import React, { useState, useEffect } from "react";
import Nav from "../../Common/Nav";
import sha256 from "../../Common/Util/SHA256";
import "./index.scss";
import * as LoginHelper from "../../Common/Util/LoginHelper";
import * as Fetch from "../../Common/Util/Fetch";

const AddAdmin = (props) => {
    const level = LoginHelper.getUser().level;
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [isAddActive, setIsAddActive] = useState(false);

    useEffect(() => {
        if (email.length >= 6 && password.length >= 6 && level === "1") {
            setIsAddActive(true);
        } else {
            setIsAddActive(false);
        }
    }, [email, password, level]);
    const onClickAdd = async () => {
        try {
            if (
                window.confirm(
                    `아이디: ${email}, 비밀번호: ${password} 로 관리자를 추가하시겠습니까?`
                )
            ) {
                const param = { email, password: sha256(password) };
                await Fetch.POST("/admin/user", param);
                setEmail("");
                setPassword("");
                window.alert("추가가 완료되었습니다.");
            }
        } catch (error) {
            console.error(error);
            return;
        }
    };

    return (
        <React.Fragment>
            <Nav pageName="AddAdmin" />
            <div className="AddAdmin">
                <div className="AddAdmin-title">{`관리자 추가하기`}</div>
                <div className="AddAdmin-subtitle">{`( 오직 Admin 관리자만 다른 관리자를 추가할 수 있습니다 )`}</div>

                <div className="AddAdmin_inputs">
                    <div className="AddAdmin_ips_wrap">
                        <div className="AddAdmin_ips-title">이메일 </div>
                        <input
                            id="email"
                            type="text"
                            className="AddAdmin_ips-input"
                            value={email}
                            onChange={(e) => {
                                setEmail(e.target.value);
                            }}
                            autoComplete="off"
                        />{" "}
                    </div>
                    <div
                        className="AddAdmin_ips_wrap"
                        style={{ marginBottom: "0px" }}
                    >
                        <div className="AddAdmin_ips-title">비밀번호 </div>
                        <input
                            id="password"
                            type="text"
                            className="AddAdmin_ips-input"
                            value={password}
                            onChange={(e) => {
                                setPassword(e.target.value);
                            }}
                            autoComplete="off"
                        />{" "}
                    </div>
                </div>
                <div
                    className={`AddAdmin_addBtn ${
                        isAddActive && "AddAdmin_addBtn-active"
                    }`}
                    onClick={onClickAdd}
                >
                    추가하기
                </div>
            </div>
        </React.Fragment>
    );
};

export default AddAdmin;
