import {
    TextField,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    Button,
    InputLabel,
    FormControl,
    NativeSelect,
} from "@material-ui/core";

const MeaningDialog = (props) => {
    const {
        handleDialog,
        onChangeTF,
        editMeaning,
        onClickEdit,
        onChangeSelect,
        onClickDelete,
    } = props;
    const type = "editMeaning";

    if (!editMeaning) return <></>;

    const isValidAnswer =
        editMeaning.answer !== undefined && editMeaning.answer !== "";

    let isValidWrongAnswers = false;
    try {
        const splited = editMeaning.wrong_answers.split(",");
        isValidWrongAnswers = splited.length >= 3 && !splited.includes("");
    } catch {
        isValidWrongAnswers = false;
    }

    const isValidMeaning =
        editMeaning.text_kor !== undefined && editMeaning.text_kor !== "";

    const isValid = isValidWrongAnswers && isValidMeaning && isValidAnswer;

    return (
        <Dialog
            open={editMeaning !== undefined}
            onClose={() => {
                handleDialog(type, false);
            }}
            aria-labelledby="form-dialog-title"
        >
            <DialogTitle id="form-dialog-title">의미 수정</DialogTitle>
            <DialogContent>
                <DialogContentText>
                    수정할 의미 정보를 입력한 후 수정 버튼을 눌러주세요
                </DialogContentText>
                <FormControl className="">
                    <InputLabel shrink htmlFor="age-native-label-placeholder">
                        품사
                    </InputLabel>
                    <NativeSelect
                        value={editMeaning.FK_part_of_speech}
                        onChange={(e) => {
                            onChangeSelect(type, e);
                        }}
                        inputProps={{
                            name: "FK_part_of_speech",
                            id: "age-native-label-placeholder",
                        }}
                    >
                        <option value={1}>명사</option>
                        <option value={2}>동사</option>
                        <option value={3}>형용사</option>
                        <option value={4}>부사</option>
                        <option value={5}>대명사</option>
                        <option value={6}>접속사</option>
                        <option value={7}>전치사</option>
                    </NativeSelect>
                </FormControl>
                <TextField
                    error={!isValidMeaning}
                    autoFocus
                    margin="dense"
                    name="text_kor"
                    label="의미"
                    type="text"
                    fullWidth
                    defaultValue={editMeaning.text_kor}
                    onChange={(e) => {
                        onChangeTF(type, e);
                    }}
                />
                <TextField
                    error={!isValidAnswer}
                    autoFocus
                    margin="dense"
                    name="answer"
                    label="정답"
                    type="text"
                    fullWidth
                    defaultValue={editMeaning.answer}
                    onChange={(e) => {
                        onChangeTF(type, e);
                    }}
                />

                <TextField
                    error={!isValidWrongAnswers}
                    autoFocus
                    margin="dense"
                    name="wrong_answers"
                    label="오답선지"
                    type="text"
                    fullWidth
                    defaultValue={editMeaning.wrong_answers}
                    onChange={(e) => {
                        onChangeTF(type, e);
                    }}
                />
                <DialogContentText>
                    모든 오답은 , 로 구분되며 최소 3개 이상의 오답 선지가 있어야
                    합니다.
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button
                    onClick={() => {
                        handleDialog(type, false);
                    }}
                >
                    취소
                </Button>
                <Button
                    variant="contained"
                    onClick={() => {
                        onClickDelete(type, editMeaning);
                    }}
                    color={"secondary"}
                >
                    의미 삭제
                </Button>
                <Button
                    variant="contained"
                    onClick={() => {
                        if (!isValid) return;
                        onClickEdit(type);
                    }}
                    color={isValid ? "primary" : "secondary"}
                >
                    수정
                </Button>
            </DialogActions>
        </Dialog>
    );
};

export default MeaningDialog;
