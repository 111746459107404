import { Component } from "react";

export default class ClabComponent extends Component {
    updateState = async (payload) => {
        return new Promise((resolve, reject) => {
            this.setState(payload, () => {
                resolve("Updated");
            });
        });
    };
}
