import { TermsText } from "../../Common/Util/Constant";

const Terms = (props) => {
    const type = props.match.params.type;
    if (type !== "TermsOfService" && type !== "PrivacyPolicy") {
        props.history.replace({
            pathname: "/",
        });
    }

    return (
        <div style={{ whiteSpace: "pre" }}>
            {TermsText[props.match.params.type]}
        </div>
    );
};

export default Terms;
