import React from "react";
import ReactTable from "react-table";
import * as CellHelper from "../../Common/Util/CellHelper";
import moment from "moment";

import Loading from "../../Asset/Image/loading.gif";

import {
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    Button,
    TextareaAutosize,
} from "@material-ui/core";

const View = (props) => {
    const { itemCount, table, data, loading, pages, pageSize } = props;
    const {
        getData,
        onClickAnswer,
        handleDialog,
        inquiry,
        onChangeAnswer,
        answerLoading,
    } = props;

    const columns = [
        CellHelper.dateCell({ key: "createdAt", header: "문의일", width: 130 }),

        {
            Header: "답변일",
            id: "updatedAt",
            accessor: (inquiry) => {
                const updatedAt = inquiry.updatedAt;
                const isAnswered = updatedAt !== inquiry.createdAt;
                if (isAnswered) {
                    return (
                        <div>{moment(updatedAt).format("M월 D일 H:mm")}</div>
                    );
                } else {
                    return <div className="Inquiry-notAnswer"> 미 답변 </div>;
                }
            },
            width: 130,
        },
        {
            Header: "문의",
            id: "question",

            accessor: (inquiry) => {
                return (
                    <div className="Inquiry-question">{inquiry.question}</div>
                );
            },
            width: 300,
        },

        {
            Header: "답변",
            id: "answer",

            accessor: (inquiry) => {
                return inquiry.answer ? (
                    <div className="Inquiry-answer">{inquiry.answer}</div>
                ) : (
                    <div
                        className="Inquiry-answerbtn"
                        onClick={() => {
                            handleDialog(inquiry);
                        }}
                    >
                        답변하기
                    </div>
                );
            },
            width: 300,
        },

        {
            Header: "답변 받을 이메일",
            accessor: "sender_email",
            width: 150,
        },
    ];

    return (
        <div className="Inquiry">
            <div className="InstaShare_wrap">
                <h2 className="InstaShare__Title">{`1:1 문의하기(총 ${itemCount}명)`}</h2>
            </div>

            <ReactTable
                ref={table}
                columns={columns}
                manual
                filterable={false}
                data={data}
                pages={pages}
                loading={loading}
                onFetchData={getData}
                defaultPageSize={pageSize}
                className="-striped -highlight"
            />

            <AnswerDialog
                handleDialog={handleDialog}
                onClickAnswer={onClickAnswer}
                inquiry={inquiry}
                onChangeAnswer={onChangeAnswer}
                answerLoading={answerLoading}
            />
        </div>
    );
};

export default View;

const AnswerDialog = (props) => {
    const {
        handleDialog,
        inquiry,
        onClickAnswer,
        onChangeAnswer,
        answerLoading,
    } = props;
    if (inquiry === undefined) return <></>;

    const answer = inquiry.answer;
    const isValid =
        answer !== "" &&
        answer !== undefined &&
        answer !== null &&
        !answerLoading;

    return (
        <Dialog
            open={inquiry !== undefined}
            onClose={() => {
                handleDialog(undefined);
            }}
            aria-labelledby="form-dialog-title"
        >
            <DialogTitle id="form-dialog-title">답변</DialogTitle>
            <DialogContent>
                <DialogContentText>
                    답변을 입력하세요. ( 발신자 정보: megaenglishvoca@gmail.com
                    )
                </DialogContentText>
                <TextareaAutosize
                    style={{
                        width: "100%",
                        height: "100px",
                        fontSize: "15px",
                    }}
                    className="Inquiry-textarea"
                    rowsMin={5}
                    placeholder="답변을 입력하세요."
                    defaultValue={inquiry.answer}
                    // value={inquiry.answer ? inquiry.answer : ""}
                    onChange={(e) => {
                        onChangeAnswer(e);
                    }}
                />
            </DialogContent>
            <DialogActions>
                <Button
                    onClick={() => {
                        handleDialog(undefined);
                    }}
                >
                    취소
                </Button>

                {answerLoading && (
                    <img
                        style={{
                            width: "30px",
                        }}
                        src={Loading}
                        alt="loading"
                    />
                )}

                <Button
                    style={{
                        opacity: answerLoading ? "0.5" : "1",
                    }}
                    variant="contained"
                    onClick={() => {
                        if (!isValid) return;
                        onClickAnswer(inquiry);
                    }}
                    color={isValid ? "primary" : "secondary"}
                >
                    답변하기
                </Button>
            </DialogActions>
        </Dialog>
    );
};
