import React from "react";
import View from "./View.js";
import * as Fetch from "../../Common/Util/Fetch.js";
import ClabComponent from "../../Common/Util/ClabComponent";

export class Container extends ClabComponent {
    constructor(props) {
        super(props);
        this.table = React.createRef();
        this.state = {
            data: [],
            pages: null,
            loading: true,
            itemCount: 0,
            pageSize: 20,
            filtered: [],

            uploadLoading: false,
            fileName: undefined,
            isValid: false,
        };
        this.file = undefined;
    }

    componentDidMount() {}

    getData = async (state, instance) => {
        try {
            await this.updateState({ loading: true });

            let { page, pageSize, sorted, filtered } = state;
            const params = {
                page,
                pageSize,
                sorted: JSON.stringify(sorted),
                filtered: JSON.stringify(filtered),
            };
            const serialNumsInfo = await Fetch.GET("/admin/serialNum", params);

            await this.updateState({
                data: serialNumsInfo.serialNums.rows,
                pages: Math.ceil(serialNumsInfo.serialNums.count / pageSize),
                loading: false,
                itemCount: serialNumsInfo.serialNums.count,
            });
        } catch (error) {
            console.error(error);
        }
    };

    refreshTable = () => {
        this.getData(this.table.current.state);
    };

    onClickAddSerialNums = () => {
        this.handleDialog(true);
    };

    handleDialog = (isShow) => {
        const state = {
            fileName: isShow ? "" : undefined,
        };
        if (!isShow) {
            state["isValid"] = false;
        }
        this.setState(state);
    };

    onUploadFile = async (e) => {
        const file = e.target.files[0];
        this.file = file;

        await this.updateState({
            fileName: file.name,
        });

        this.setState({
            isValid:
                this.state.fileName !== undefined &&
                this.state.fileName !== "" &&
                this.file !== undefined,
        });
    };

    onClickAdd = async () => {
        const { file } = this;
        await this.updateState({
            uploadLoading: true,
        });
        const json = await this.uploadFile(file);

        if (!json) {
            alert("파일 업로드에 실패했습니다");
            await this.updateState({
                uploadLoading: false,
            });
            return;
        }

        const skipSerialNums = json.skipSerialNums.join(", ");
        alert(
            `성공했습니다 \n추가된 시리얼 넘버 개수: ${json.addedCount}\n추가되지 않은 시리얼 넘버: ${skipSerialNums}`
        );
        this.handleDialog(false);
        this.refreshTable();
        await this.updateState({
            uploadLoading: false,
        });
    };

    uploadFile = async (file) => {
        const fileType = file.type.split("/")[0];
        const data = new FormData();
        switch (fileType) {
            case "application":
                data.append("file", file);
                break;
            default:
                console.warn("uploadFile::unhandled fileType -", fileType);
                return null;
        }
        try {
            const json = await Fetch.POST("/upload/admin/serialNums", data);
            return json;
        } catch (err) {
            console.error(err);
            return null;
        }
    };

    onClickDeleteSerialNumber = async (id) => {
        try {
            const params = { id };
            const json = await Fetch.DELETE("/admin/serialNum", params);
            if (json.success) {
                window.alert("해당 코드가 비활성화 되었습니다.");
                this.refreshTable();
            } else {
                window.alert("해당 코드를 비활성화할 수 없습니다.");
            }
        } catch (error) {
            console.error(error);
        }
    };

    onChangeSearchInput = (e) => {
        const name = e.target.name;
        const value = e.target.value;
        const filterObj = {
            id: name,
            value,
        };
        const { filtered } = this.state;
        if (filtered.length === 0) {
            filtered.push(filterObj);
        } else {
            const index = filtered.findIndex(
                (filterObj) => filterObj.id === name
            );

            if (index >= 0) {
                filtered[index] = filterObj;
            } else {
                filtered.push(filterObj);
            }
        }

        this.setState({
            filtered,
        });
    };

    onClickSearch = () => {
        this.refreshTable();
    };

    render() {
        const {
            itemCount,
            data,
            loading,
            pages,
            pageSize,
            filtered,
        } = this.state;

        const { getData, onClickAddSerialNums } = this;

        const { fileName, isValid, uploadLoading } = this.state;
        const {
            handleDialog,
            onUploadFile,
            onClickAdd,
            onClickDeleteSerialNumber,
            onChangeSearchInput,
            onClickSearch,
        } = this;
        return (
            <View
                table={this.table}
                itemCount={itemCount}
                data={data}
                loading={loading}
                pages={pages}
                filtered={filtered}
                pageSize={pageSize}
                getData={getData}
                onClickAddSerialNums={onClickAddSerialNums}
                fileName={fileName}
                isValid={isValid}
                handleDialog={handleDialog}
                onUploadFile={onUploadFile}
                onClickAdd={onClickAdd}
                uploadLoading={uploadLoading}
                onClickDeleteSerialNumber={onClickDeleteSerialNumber}
                onChangeSearchInput={onChangeSearchInput}
                onClickSearch={onClickSearch}
            />
        );
    }
}

export default Container;
