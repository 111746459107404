import React, { useState, useEffect } from "react";
import Nav from "../../Common/Nav";
import "./index.scss";
import * as Fetch from "../../Common/Util/Fetch";
import {TextareaAutosize, TextField} from "@material-ui/core";

const SendEmail = (props) => {
    const [email, setEmail] = useState("");
    const [content, setContent] = useState("");
    const [isOn, setIsOn] = useState(0);

    const onClickSend = async () => {
        try {
            if (
                window.confirm(
                    `발송하시겠습니까?`
                )
            ) {
                const param = { email: email, content: content, isOn: isOn };
                await Fetch.POST("/admin/email", param);
                window.alert("발송되었습니다.");
            }
        } catch (error) {
            console.error(error);
            return;
        }
    };

    return (
        <React.Fragment>
            <Nav pageName="AddNotice" />
            <div className="AddNotice">
                <div className="AddNotice-title">{`메일보내기`}</div>

                <div className="AddNotice_inputs">
                    <div className="AddNotice_ips_wrap">
                        <div className="AddNotice_ips-title">이메일 </div>
                        <TextField
                            id="email"
                            className="AddNotice_ips-input"
                            name="email"
                            type="text"
                            label="email"
                            placeholder=",으로 여러개 입력"
                            onChange={(e) => {
                                setEmail(e.target.value);
                            }}
                        />
                    </div>
                    <div className="AddNotice_ips_wrap">
                        <div className="AddNotice_ips-title">내용 </div>
                        <TextareaAutosize
                            id="content"
                            style={{
                                width: "100%",
                                height: "100px",
                                fontSize: "15px",
                            }}
                            className="AddNotice_ips-input"
                            rowsMin={5}
                            placeholder="메일내용을 입력하세요."
                            defaultValue={content}
                            // value={inquiry.answer ? inquiry.answer : ""}
                            onChange={(e) => {
                                setContent(e.target.value);
                            }}
                        />
                    </div>
                    <div
                        className="AddNotice_ips_wrap"
                        style={{ marginBottom: "0px" }}
                    >
                        <div className="AddNotice_ips-title"> </div>
                        <input type="radio" id="radio_on" name="radio_show" value="radio_on" checked={isOn===1} onChange={(e) => {
                            setIsOn(1);
                        }}/>
                        <label htmlFor="radio_on">전체 발송</label>
                        <input type="radio" id="radio_off" name="radio_show" value="radio_off" checked={isOn===0} onChange={(e) => {
                            setIsOn(0);
                        }}/>
                        <label htmlFor="radio_off">선택 발송(이메일 입력)</label>
                    </div>
                </div>
                <div
                    className={`AddNotice_addBtn AddNotice_addBtn-active`}
                    onClick={onClickSend}
                >
                    보내기
                </div>
            </div>
        </React.Fragment>
    );
};

export default SendEmail;
