import React from "react";
import "./index.scss";
import moment from "moment";

export const booleanCellHandler = (boolean) => {
    if (boolean) {
        return <div style={{ color: "#2ad2c9", fontWeight: "bold" }}>ON</div>;
    } else {
        return <div style={{ color: "red", fontWeight: "bold" }}>OFF</div>;
    }
};

export const dateCell = (params) => {
    const { key, header, width = undefined } = params;
    const cell = {
        Header: header,
        Cell: (row) => {
            const date = row.original[key];
            return moment(date).format("M월 D일 H:mm");
        },
    };

    if (width !== undefined) {
        cell.width = width;
    }
    return cell;
};

export const deleteCell = (params) => {
    const { page, title, eventHanlder } = params;
    return {
        Header: "삭제정보",
        Cell: (row) => {
            const data = row.original;
            if (data.deletedAt) {
                return moment(data.deletedAt).format("MM-DD HH:mm");
            } else {
                return deleteButton(page, title, data, eventHanlder);
            }
        },
    };
};

const deleteButton = (page, title, data, eventHanlder) => {
    return (
        <div className="Cell_center">
            <div
                className="Cell-deleteBtn"
                onClick={() => {
                    switch (page) {
                        case "User":
                            eventHanlder(data.id);
                            break;
                        default:
                            break;
                    }
                }}
            >
                {title}
            </div>
        </div>
    );
};
