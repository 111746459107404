import React, { Component } from "react";
import { Link, withRouter } from "react-router-dom";
import "./index.scss";
import "react-table/react-table.css";
import * as LoginHelper from "../Util/LoginHelper";

class Nav extends Component {
    componentDidMount() {
        if (!LoginHelper.isLogin()) {
            this.props.history.replace({
                pathname: "/",
            });
            return;
        }
        window.scrollTo(0, 0);
        const { pageName } = this.props;
        const classes = document.getElementsByClassName(
            `Menu__Item__${pageName}`
        );
        if (classes && classes[0]) {
            classes[0].classList.add("Menu__Active");
        }
    }

    onClickLogout() {
        if (window.confirm("정말 로그아웃 하시겠습니까?")) {
            LoginHelper.logout();
            window.location.reload();
        }
    }

    render() {
        const style = { textDecoration: "none", color: "#ffffff" };
        const liStyle = { textDecoration: "none" };
        // <i> icon reference : https://www.w3schools.com/icons/icons_reference.asp
        return (
            <section className="Menu">
                <h3 className="Menu__Title">
                    <Link style={style} to="/">
                        Clabvoca Administrator
                    </Link>
                </h3>
                {/* 1 */}
                {/* <ul className="Menu__Items">
                    <Link style={liStyle} to="/">
                        <li className="Menu__Item Menu__Item__Home">
                            <i className="fas fa-home" />
                            <span>홈</span>
                        </li>
                    </Link>
                </ul> */}

                {/* User */}
                <ul className="Menu__Items">
                    <h5 className="Menu__Items__Title">
                        회원
                        <hr />
                    </h5>
                    <Link style={liStyle} to="/users">
                        <li className="Menu__Item Menu__Item__User">
                            <i className="far fa-address-card" />
                            <span>회원 목록</span>
                        </li>
                    </Link>
                    <Link style={liStyle} to="/instashare">
                        <li className="Menu__Item Menu__Item__InstaShare">
                            <i className="fab fa-instagram"></i>
                            <span>인스타그램 공유 유저</span>
                        </li>
                    </Link>
                </ul>

                {/* Word */}
                <ul className="Menu__Items">
                    <h5 className="Menu__Items__Title">
                        단어
                        <hr />
                    </h5>
                    <Link style={liStyle} to="/words">
                        <li className="Menu__Item Menu__Item__Word">
                            <i className="fas fa-book"></i>
                            <span>단어 목록</span>
                        </li>
                    </Link>
                </ul>

                <ul className="Menu__Items">
                    <h5 className="Menu__Items__Title">
                        앱관리
                        <hr />
                    </h5>
                    <Link style={liStyle} to="/sendEmail">
                        <li className="Menu__Item Menu__Item__Email">
                            <i className="fas fa-user-headset"></i>
                            <span>메일보내기</span>
                        </li>
                    </Link>
                </ul>

                <ul className="Menu__Items">
                    <h5 className="Menu__Items__Title">
                        고객센터
                        <hr />
                    </h5>
                    <Link style={liStyle} to="/inquiry">
                        <li className="Menu__Item Menu__Item__Inquiry">
                            <i className="fas fa-user-headset"></i>
                            <span>1:1 문의하기</span>
                        </li>
                    </Link>
                    <Link style={liStyle} to="/serialnum">
                        <li className="Menu__Item Menu__Item__SerialNum">
                            <i className="fas fa-key"></i>
                            <span>시리얼 넘버 목록</span>
                        </li>
                    </Link>
                </ul>

                {/* Account */}
                <ul className="Menu__Items">
                    <h5 className="Menu__Items__Title">
                        계정
                        <hr />
                    </h5>
                    <Link style={liStyle} to="/addAdmin">
                        <li className="Menu__Item Menu__Item__AddAdmin">
                            <i className="fas fa-key"></i>
                            <span>관리자 추가</span>
                        </li>
                    </Link>
                    <li className="Menu__Item" onClick={this.onClickLogout}>
                        <i className="fas fa-unlink" />
                        <span>로그아웃</span>
                    </li>
                </ul>

                {/* <div className="Menu__Footer">{`대표: 조정식\n사업자 등록번호: 238-93-00529\n주소:서울특별시 강남구 삼성로 155, 4층 420호(대치동, 대치퍼스트빌딩)\n2021 © Clabvoca (씨랩보카)`}</div> */}
            </section>
        );
    }
}

export default withRouter(Nav);
