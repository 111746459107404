import React from "react";
import Nav from "../../Common/Nav";
import "./index.scss";
import Container from "./Container";

const Home = props => {
  return (
    <React.Fragment>
      <Nav pageName="Home" />
      <Container {...props} />
    </React.Fragment>
  );
};

export default Home;
