import React from "react";
import ReactTable from "react-table";
import * as CellHelper from "../../Common/Util/CellHelper";

const View = (props) => {
    const { itemCount, table, data, loading, pages, pageSize } = props;
    const { getData } = props;

    const columns = [
        {
            Header: "이메일",
            id: "email",

            accessor: (instashare) => {
                return (
                    <div className="InstaShare-textEng">
                        {instashare.FK_user_User.email}
                    </div>
                );
            },
            width: 200,
        },
        {
            Header: "닉네임",
            id: "nickname",

            accessor: (instashare) => {
                return (
                    <div className="InstaShare-textEng">
                        {instashare.FK_user_User.nickname}
                    </div>
                );
            },
            width: 100,
        },
        {
            Header: "나이",
            id: "age",

            accessor: (instashare) => {
                const ageType = instashare.FK_user_User.age_type;
                let age = "";
                switch (ageType) {
                    case 1:
                        age = "중3";
                        break;
                    case 2:
                        age = "고1";
                        break;
                    case 3:
                        age = "고2";
                        break;
                    case 4:
                        age = "고3";
                        break;
                    default:
                        age = "기타";
                        break;
                }

                return <div className="InstaShare-textEng">{age}</div>;
            },
            width: 100,
        },
        CellHelper.dateCell({ key: "createdAt", header: "공유일", width: 200 }),
    ];

    return (
        <div className="InstaShare">
            <div className="InstaShare_wrap">
                <h2 className="InstaShare__Title">{`인스타 공유 유저(총 ${itemCount}명)`}</h2>
            </div>

            <ReactTable
                ref={table}
                columns={columns}
                manual
                filterable={false}
                data={data}
                pages={pages}
                loading={loading}
                onFetchData={getData}
                defaultPageSize={pageSize}
                className="-striped -highlight"
            />
        </div>
    );
};

export default View;
