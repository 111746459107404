import React from "react";
import ReactTable from "react-table";
import Highlighter from "react-highlight-words";
import ExDialog from "./Component/ExDialog";
import MeaningDialog from "./Component/MeaningDialog";
import WordDialog from "./Component/WordDialog";
import { Button, InputAdornment, TextField } from "@material-ui/core";
import SearchIcon from "@material-ui/icons/Search";

const View = (props) => {
    const {
        itemCount,
        table,
        data,
        loading,
        pages,
        pageSize,
        filtered,
    } = props;
    const { getData, onChangeSearchInput, onClickSearch } = props;

    // dialog
    const {
        handleDialog,
        onChangeTF,
        editWord,
        editMeaning,
        editEx,
        addWord,

        onClickDelete,
        onClickEdit,
        onChangeSelect,
        onHandleMeaningBoxInAddWord,
        onChangeMeaningBox,

        onHandleExBoxInAddWord,
        onChangeExBox,
        onClickPlayAudio,
        onClickDeleteWord,
        fetchLoading,
    } = props;

    const columns = [
        {
            Header: "수정",
            id: "editBtn",
            filterable: false,
            sortable: false,
            Cell: (row) => {
                return (
                    <div>
                        <div
                            className="Words-editBtn"
                            onClick={() => {
                                handleDialog("editWord", true, row.original);
                            }}
                        >
                            수정하기
                        </div>
                        <div
                            className="Words-editBtn"
                            style={{ marginTop: "10px", fontSize: "12px" }}
                            onClick={() => {
                                const defaultAddWord = {
                                    uniqueIndex: row.original.uniqueIndex + 1,
                                    text_eng: undefined,
                                    audio_symbol: undefined,
                                    FK_chapter: undefined,
                                    additionalWords: undefined,
                                    additionalMeanings: undefined,
                                    Meanings: [],
                                };
                                handleDialog("addWord", true, defaultAddWord);
                            }}
                        >
                            아래에 추가
                        </div>
                        <div
                            className="Words-deleteBtn"
                            onClick={() => {
                                onClickDeleteWord(row.original.id);
                            }}
                        >
                            삭제하기
                        </div>
                    </div>
                );
            },
            width: 70,
        },
        {
            Header: "색인",
            accessor: "uniqueIndex",
            width: 50,
        },
        {
            Header: "단어",
            id: "text_eng",
            // Filter: (cellInfo) => {
            //     return (
            //         <TextField
            //             error={false}
            //             // autoFocus
            //             margin="dense"
            //             name="answer"
            //             type="text"
            //             InputProps={{
            //                 startAdornment: (
            //                     <InputAdornment position="start">
            //                         <SearchIcon />
            //                     </InputAdornment>
            //                 ),
            //             }}
            //             fullWidth
            //             defaultValue={filter ? filter.value : ""}
            //             onChange={(e) => {
            //                 onChangeSearchInput(e);
            //             }}
            //         />
            //     );
            // },
            accessor: (word) => {
                return <div className="Words-textEng">{word.text_eng}</div>;
            },
            width: 150,
        },
        {
            Header: "음성기호",
            accessor: "audio_symbol",
            filterable: false,
            sortable: false,
            width: 100,
        },
        {
            Header: "챕터 정보",
            id: "FK_chapter_Chapter",
            width: 100,
            accessor: (word) => {
                const chapter = word.FK_chapter_Chapter;
                if (chapter) {
                    return `${chapter.number}. ${chapter.title}`;
                } else {
                    return <div style={{ color: "red" }}> Nothing</div>;
                }
            },
            filterable: false,
            sortable: false,
        },
        {
            Header: "의미",
            id: "Meanings",
            filterable: false,
            sortable: false,
            accessor: (word) => {
                const meanings = word.Meanings;
                return (
                    <div className="Words_Meaning">
                        {meanings.map((meaning, index) => {
                            return (
                                <Meaning
                                    meaning={meaning}
                                    meaningCount={meanings.length}
                                    key={`Meaning-${index}`}
                                    handleDialog={handleDialog}
                                />
                            );
                        })}
                    </div>
                );
            },
        },
    ];

    return (
        <div className="Words">
            <div className="Words_wrap">
                <h2 className="Words__Title">{`단어 목록(총 ${itemCount}개)`}</h2>

                <TextField
                    error={false}
                    margin="dense"
                    name="text_eng"
                    type="text"
                    InputProps={{
                        startAdornment: (
                            <InputAdornment position="start">
                                <SearchIcon />
                            </InputAdornment>
                        ),
                    }}
                    onChange={(e) => {
                        onChangeSearchInput(e);
                    }}
                />
                <Button
                    variant="contained"
                    style={{
                        backgroundColor: "#2ad2c9",
                        color: "white",
                        marginLeft: "5px",
                        height: "30px",
                        width: "50px",
                    }}
                    onClick={() => {
                        onClickSearch();
                    }}
                >
                    검색
                </Button>
            </div>

            <ReactTable
                ref={table}
                columns={columns}
                manual
                filtered={filtered}
                sortable={true}
                data={data}
                pages={pages}
                loading={loading}
                onFetchData={getData}
                defaultPageSize={pageSize}
                className="-striped -highlight"
            />

            <MeaningDialog
                handleDialog={handleDialog}
                onChangeTF={onChangeTF}
                editMeaning={editMeaning}
                onClickEdit={onClickEdit}
                onChangeSelect={onChangeSelect}
                onClickDelete={onClickDelete}
            />

            <ExDialog
                handleDialog={handleDialog}
                onChangeTF={onChangeTF}
                editEx={editEx}
                onClickEdit={onClickEdit}
                onClickDelete={onClickDelete}
                onClickPlayAudio={onClickPlayAudio}
            />

            <WordDialog
                fetchLoading={fetchLoading}
                handleDialog={handleDialog}
                onChangeTF={onChangeTF}
                word={addWord ? addWord : editWord}
                type={addWord ? "addWord" : "editWord"}
                onClickEdit={onClickEdit}
                onChangeSelect={onChangeSelect}
                onHandleMeaningBoxInAddWord={onHandleMeaningBoxInAddWord}
                onChangeMeaningBox={onChangeMeaningBox}
                onHandleExBoxInAddWord={onHandleExBoxInAddWord}
                onChangeExBox={onChangeExBox}
                onClickPlayAudio={onClickPlayAudio}
            />
        </div>
    );
};

const Meaning = (props) => {
    const { meaning, handleDialog, meaningCount } = props;
    const exampleSentences = meaning.ExampleSentences;
    const abbreviation = meaning.FK_part_of_speech_PartOfSpeech.abbreviation;
    let wrongs = meaning.wrong_answers;
    if (wrongs) {
        wrongs = wrongs.split(",");
    }

    const type = "editMeaning";
    return (
        <div className="Meaning">
            <div
                className="Meaning_wrap"
                onClick={() => {
                    meaning.meaningCount = meaningCount;
                    handleDialog(type, true, meaning);
                }}
            >
                <div className="Meaning_wp-text">
                    {`${abbreviation}. ${meaning.text_kor}`}
                </div>
                <div className="Meaning_wp-wrongs">
                    {wrongs ? (
                        wrongs.map((wrong, index) => (
                            <div
                                key={`Meaning_wp-wrongs-${index}`}
                                className="Meaning_wp-wrongs-wrongTag"
                            >
                                {wrong}
                            </div>
                        ))
                    ) : (
                        <div style={{ color: "red" }}>오답 없음</div>
                    )}
                </div>
            </div>

            {exampleSentences.map((ex, index) => {
                let wrongs = ex.wrong_answers;
                if (wrongs) {
                    wrongs = wrongs.split(",");
                }

                return (
                    <ExampleSentence
                        ex={ex}
                        exCount={exampleSentences.length}
                        wrongs={wrongs}
                        key={`ExampleSentence-${index}`}
                        handleDialog={handleDialog}
                    />
                );
            })}
        </div>
    );
};

const ExampleSentence = (props) => {
    const { ex, exCount, wrongs, handleDialog } = props;
    const type = "editEx";
    return (
        <div
            className="ExampleSentence"
            onClick={() => {
                ex.exCount = exCount;
                handleDialog(type, true, ex);
            }}
        >
            <Highlighter
                highlightClassName="ExampleSentence-eng"
                searchWords={[ex.text_highlight]}
                autoEscape={true}
                textToHighlight={ex.text_eng}
            />
            <div className="ExampleSentence-kor">{ex.text_kor}</div>
            <div className="ExampleSentence_wrongs">
                {wrongs ? (
                    wrongs.map((wrong, index) => (
                        <div
                            key={`ExampleSentence_wrongs-${index}`}
                            className="ExampleSentence_wr-wrongTag"
                        >
                            {wrong}
                        </div>
                    ))
                ) : (
                    <div style={{ color: "red" }}>오답 없음</div>
                )}
            </div>
        </div>
    );
};

export default View;
