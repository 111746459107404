import React from "react";
import Nav from "../../Common/Nav";
import "./index.scss";
import Container from "./Container";

const Inquiry = (props) => {
    return (
        <React.Fragment>
            <Nav pageName="Inquiry" />
            <Container {...props} />
        </React.Fragment>
    );
};

export default Inquiry;
