import React from "react";
import ReactTable from "react-table";
import { Add } from "@material-ui/icons";
import {
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    Button,
    Tooltip,
    TextField,
} from "@material-ui/core";

import SerialPreview from "../../Asset/Image/SerialNumberPreview.png";
import Loading from "../../Asset/Image/loading.gif";

const View = (props) => {
    const {
        itemCount,
        table,
        data,
        loading,
        pages,
        pageSize,
        filtered,
    } = props;
    const { getData, onClickAddSerialNums } = props;

    const { onChangeSearchInput, onClickSearch } = props;

    const {
        uploadLoading,
        fileName,
        handleDialog,
        onUploadFile,
        onClickAdd,
        isValid,
        onClickDeleteSerialNumber,
    } = props;

    const columns = [
        {
            Header: "시리얼 넘버",
            accessor: "serial_num",
            width: 200,
        },
        {
            Header: "사용자",
            id: "isActive",

            accessor: (serialNum) => {
                const isActive =
                    serialNum.FK_user_User !== null &&
                    serialNum.FK_user_User !== undefined &&
                    serialNum.FK_user_User.email !== undefined;
                return (
                    <div className="SerialNum-textEng">
                        {isActive ? serialNum.FK_user_User.email : "사용가능"}
                    </div>
                );
            },
            width: 400,
        },
        {
            Header: "비활성화",
            id: "deletedAt",
            accessor: (serialNum) => {
                if (
                    serialNum.deletedAt === null &&
                    serialNum.FK_user === null
                ) {
                    return (
                        <div
                            className="SerialNum-deleteBtn"
                            onClick={() => {
                                onClickDeleteSerialNumber(serialNum.id);
                            }}
                        >
                            비활성화하기
                        </div>
                    );
                }
            },
            width: 100,
        },
    ];

    return (
        <div className="SerialNum">
            <div className="SerialNum_wrap">
                <h2 className="SerialNum__Title">{`시리얼 넘버(총 ${itemCount}개)`}</h2>
                <Button
                    variant="contained"
                    style={{
                        backgroundColor: "#2ad2c9",
                        color: "white",
                        marginRight: "10px",
                    }}
                    onClick={onClickAddSerialNums}
                    startIcon={<Add />}
                >
                    시리얼 넘버 추가하기
                </Button>
                <TextField
                    style={{
                        marginBottom: "15px",
                        marginRight: "5px",
                    }}
                    error={false}
                    margin="dense"
                    name="serial_num"
                    type="text"
                    label="Serial Number"
                    onChange={(e) => {
                        onChangeSearchInput(e);
                    }}
                />
                <TextField
                    style={{
                        marginBottom: "15px",
                    }}
                    error={false}
                    margin="dense"
                    name="email"
                    type="text"
                    label="email"
                    onChange={(e) => {
                        onChangeSearchInput(e);
                    }}
                />
                <Button
                    variant="contained"
                    style={{
                        backgroundColor: "#2ad2c9",
                        color: "white",
                        marginLeft: "5px",
                        height: "30px",
                        width: "50px",
                    }}
                    onClick={() => {
                        onClickSearch();
                    }}
                >
                    검색
                </Button>
            </div>

            <ReactTable
                ref={table}
                columns={columns}
                manual
                filtered={filtered}
                data={data}
                pages={pages}
                loading={loading}
                onFetchData={getData}
                defaultPageSize={pageSize}
                className="-striped -highlight"
            />

            <Dialog
                open={fileName !== undefined}
                onClose={() => {
                    handleDialog(false);
                }}
                aria-labelledby="form-dialog-title"
            >
                <DialogTitle id="form-dialog-title">
                    시리얼 넘버 추가
                </DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        추가하고자 하는 시리얼 넘버 엑셀 파일을 업로드 해주세요.
                    </DialogContentText>
                    <Tooltip
                        title={
                            <img
                                className="exbox-previewImage"
                                src={SerialPreview}
                                alt=""
                            />
                        }
                        placement="right"
                        arrow
                    >
                        <div className="exbox-preview">엑셀파일</div>
                    </Tooltip>

                    <div
                        style={{ marginBottom: "10px" }}
                        className="WordDialog_audio"
                    >
                        <div style={{ color: "gray" }}></div>
                        <div
                            className="WordDialog_ad-editBtn"
                            onClick={() => {
                                document
                                    .getElementById(`input-upload-serialnums`)
                                    .click();
                            }}
                        >
                            {fileName === undefined || fileName === ""
                                ? "업로드"
                                : "수정"}
                        </div>
                        <div
                            style={{
                                marginLeft: "01px",
                            }}
                        >
                            {fileName}
                        </div>
                        <input
                            id={`input-upload-serialnums`}
                            type="file"
                            name="file"
                            accept=".xlsx"
                            onChange={(e) => {
                                onUploadFile(e);
                            }}
                            hidden
                        />
                    </div>
                    <DialogContentText>
                        1. 만약 이미 존재하는 시리얼 넘버가 있다면 생성하지 않고
                        건너뜁니다.
                    </DialogContentText>
                    <DialogContentText>
                        2. 위의 엑셀파일 포맷이 지켜지지 않으면 정상적인 추가가
                        이루어지지 않습니다.
                    </DialogContentText>
                    <DialogContentText>
                        3. 파일 크기 제한: 10MB
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button
                        onClick={() => {
                            handleDialog(false);
                        }}
                    >
                        취소
                    </Button>
                    {uploadLoading && (
                        <img
                            style={{
                                width: "30px",
                            }}
                            src={Loading}
                            alt="loading"
                        />
                    )}
                    <Button
                        variant="contained"
                        onClick={() => {
                            if (!isValid) return;
                            onClickAdd();
                        }}
                        color={isValid ? "primary" : "secondary"}
                    >
                        추가하기
                    </Button>
                </DialogActions>
            </Dialog>
        </div>
    );
};

export default View;
