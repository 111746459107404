import React from "react";
import View from "./View.js";
import * as Fetch from "../../Common/Util/Fetch.js";
import ClabComponent from "../../Common/Util/ClabComponent";
import { URL } from "../../Common/Util/Constant";

export class Container extends ClabComponent {
    constructor(props) {
        super(props);
        this.table = React.createRef();
        this.state = {
            data: [],
            pages: null,
            loading: true,
            itemCount: 0,
            pageSize: 20,
            filtered: [],
            sorted: [],

            fetchLoading: false,
            editWord: undefined,
            editMeaning: undefined,
            editEx: undefined,

            addWord: undefined,
        };
    }

    componentDidMount() {}

    getData = async (state, instance) => {
        try {
            await this.updateState({ loading: true });

            let { page, pageSize, sorted, filtered } = state;
            const params = {
                page,
                pageSize,
                sorted: JSON.stringify(sorted),
                filtered: JSON.stringify(filtered),
            };

            const wordsInfo = await Fetch.GET("/admin/words", params);

            await this.updateState({
                data: wordsInfo.words.rows,
                pages: Math.ceil(wordsInfo.words.count / pageSize),
                loading: false,
                itemCount: wordsInfo.words.count,
            });
        } catch (error) {
            console.error(error);
        }
    };

    refreshTable = () => {
        this.getData(this.table.current.state);
    };

    onChangeSearchInput = (e) => {
        const name = e.target.name;
        const value = e.target.value;

        const filterObj = {
            id: name,
            value,
        };

        const { filtered } = this.state;
        if (filtered.length === 0) {
            filtered.push(filterObj);
        } else {
            filtered[0] = filterObj;
        }

        this.setState({
            filtered,
        });
    };

    onClickSearch = () => {
        this.refreshTable();
    };

    handleDialog = async (type, isOpen, editData) => {
        if (
            type !== "editWord" &&
            type !== "editMeaning" &&
            type !== "editEx" &&
            type !== "addWord"
        )
            return;

        if (isOpen) {
            if (editData !== undefined) {
                if (type === "editWord") {
                    if (editData.Meanings) {
                        for (let i = 0; i < editData.Meanings.length; i++) {
                            const meaning = editData.Meanings[i];
                            meaning.isValid = this.isValidMeaning(meaning);

                            if (meaning.ExampleSentences)
                                for (
                                    let j = 0;
                                    j < meaning.ExampleSentences.length;
                                    j++
                                ) {
                                    const ex = meaning.ExampleSentences[j];
                                    ex.isValid = this.isVaildExampleSentence(
                                        ex
                                    );
                                }
                        }
                    }
                }
                await this.updateState({
                    [type]: JSON.parse(JSON.stringify(editData)),
                });
            }
        } else {
            await this.updateState({
                [type]: undefined,
            });
            await this.configureLoading(false);
        }
    };

    onHandleMeaningBoxInAddWord = async (type, isAdd) => {
        const word = this.state[type];
        if (!word || !word.Meanings) return;

        if (isAdd === true) {
            const defaultMeaning = {
                FK_part_of_speech: -1,
                text_kor: undefined,
                answer: undefined,
                wrong_answers: undefined,
                isValid: false,
                ExampleSentences: [],
            };
            word.Meanings.push(defaultMeaning);
        } else {
            if (word.Meanings.length <= 0) return;
            word.Meanings.pop();
        }

        await this.updateState({
            [type]: word,
        });
        if (isAdd === true) {
            return word.Meanings.length - 1;
        }
    };

    onChangeMeaningBox = (type, index, e) => {
        const word = this.state[type];
        const Meanings = word.Meanings;
        if (!Meanings || Meanings.length <= 0) return;
        const meaning = Meanings[index];
        const name = e.target.name;
        const value = e.target.value;
        meaning[name] = value;
        meaning.isValid = this.isValidMeaning(meaning);
        this.setState({
            [type]: word,
        });
    };

    isValidMeaning = (meaning) => {
        const { FK_part_of_speech, text_kor, wrong_answers, answer } = meaning;
        const isValidPos = FK_part_of_speech !== -1;
        const isValidTextKor = text_kor !== undefined && text_kor !== "";
        let isValidWrongAnswers = false;
        try {
            const splited = wrong_answers.split(",");
            isValidWrongAnswers = splited.length >= 3 && !splited.includes("");
        } catch {
            isValidWrongAnswers = false;
        }

        const isValidAnswer = answer !== undefined && answer !== "";
        return (
            isValidPos && isValidTextKor && isValidWrongAnswers && isValidAnswer
        );
    };

    onHandleExBoxInAddWord = (type, isAdd, meaningIndex) => {
        const word = this.state[type];
        const Meanings = word.Meanings;
        const meaning = Meanings[meaningIndex];
        if (!meaning || !meaning.ExampleSentences) return;

        if (isAdd === true) {
            const defaultEx = {
                text_eng: undefined,
                text_kor: undefined,
                text_highlight: undefined,
                wrong_answers: undefined,
                audio_url: undefined,
                isValid: false,
            };
            meaning.ExampleSentences.push(defaultEx);
        } else {
            if (meaning.ExampleSentences.length <= 0) return;
            meaning.ExampleSentences.pop();
        }
        this.setState({
            [type]: word,
        });
    };

    onChangeExBox = async (type, meaningIndex, exIndex, e) => {
        const word = this.state[type];

        const Meanings = word.Meanings;
        const meaing = Meanings[meaningIndex];
        const ExampleSentences = meaing.ExampleSentences;
        if (!ExampleSentences || ExampleSentences.length <= 0) return;

        const ex = ExampleSentences[exIndex];

        if (e.target.files) {
            const file = e.target.files[0];
            const json = await this.uploadFileToS3(file);
            const fileName = json.fileName;
            ex["audio_url"] = fileName;
        } else {
            const name = e.target.name;
            const value = e.target.value;
            ex[name] = value;
            ex.isValid = this.isVaildExampleSentence(ex);
        }

        this.setState({
            [type]: word,
        });
    };

    isVaildExampleSentence = (ex) => {
        let isValidWrongAnswers = false;
        try {
            const splited = ex.wrong_answers.split(",");
            isValidWrongAnswers = splited.length >= 3 && !splited.includes("");
        } catch {
            isValidWrongAnswers = false;
        }

        const isValidTextEng = ex.text_eng !== undefined && ex.text_eng !== "";
        const isValidTextKor = ex.text_kor !== undefined && ex.text_kor !== "";
        const isValidTextHighlight =
            ex.text_highlight !== undefined &&
            ex.text_highlight !== "" &&
            ex.text_highlight !== " " &&
            ex.text_eng.includes(ex.text_highlight);

        return (
            isValidWrongAnswers &&
            isValidTextEng &&
            isValidTextKor &&
            isValidTextHighlight
        );
    };

    onChangeTF = async (type, e) => {
        if (
            type !== "editWord" &&
            type !== "editMeaning" &&
            type !== "editEx" &&
            type !== "addWord"
        ) {
            return;
        }

        const editData = this.state[type];
        if (!editData) {
            return;
        }

        if (e.target.files) {
            const file = e.target.files[0];
            const json = await this.uploadFileToS3(file);
            if (!json || !json.fileName) {
                alert("파일 업로드에 실패했습니다");
                return;
            }
            const fileName = json.fileName;
            editData["audio_url"] = fileName;
        } else {
            const name = e.target.name;
            const value = e.target.value;
            editData[name] = value;
        }

        this.setState({
            [type]: editData,
        });
    };
    onChangeSelect = (type, e) => {
        if (
            type !== "editWord" &&
            type !== "editMeaning" &&
            type !== "editEx" &&
            type !== "addWord"
        )
            return;

        const value = e.target.value; // id
        const name = e.target.name;

        const editData = this.state[type];
        if (!editData) return;
        editData[name] = value;

        this.setState({
            [type]: editData,
        });
    };

    onClickEdit = async (type) => {
        if (
            type !== "editWord" &&
            type !== "editMeaning" &&
            type !== "editEx" &&
            type !== "addWord"
        )
            return;

        const data = this.state[type];

        let params = {};

        try {
            switch (type) {
                case "editWord":
                    params = {
                        wordId: data.id,
                        text_eng: data.text_eng,
                        audio_symbol: data.audio_symbol,
                        additionalWords: data.additionalWords,
                        additionalMeanings: data.additionalMeanings,
                        Meanings: data.Meanings,
                        audio_url: data.audio_url,
                    };
                    await Fetch.PUT("/admin/word", params);
                    break;
                case "editMeaning":
                    params = {
                        meaningId: data.id,
                        FK_part_of_speech: data.FK_part_of_speech,
                        text_kor: data.text_kor,
                        wrong_answers: data.wrong_answers,
                        answer: data.answer,
                    };
                    await Fetch.PUT("/admin/meaning", params);
                    break;
                case "editEx":
                    params = {
                        exId: data.id,
                        text_eng: data.text_eng,
                        text_kor: data.text_kor,
                        text_highlight: data.text_highlight,
                        wrong_answers: data.wrong_answers,
                    };
                    await Fetch.PUT("/admin/ex", params);
                    break;
                case "addWord":
                    await this.configureLoading(true);
                    params = {
                        uniqueIndex: data.uniqueIndex,
                        text_eng: data.text_eng,
                        audio_symbol: data.audio_symbol,
                        FK_chapter: data.FK_chapter,
                        additionalWords: data.additionalWords,
                        additionalMeanings: data.additionalMeanings,
                        Meanings: data.Meanings,
                        audio_url: data.audio_url,
                    };
                    await Fetch.POST("/admin/word", params);

                    break;
                default:
                    break;
            }
            this.handleDialog(type, false);
            this.refreshTable();
        } catch (error) {
            console.error(error);
            await this.configureLoading(false);
        }
    };

    onClickDelete = async (type, data) => {
        try {
            switch (type) {
                case "editMeaning":
                    const meaning = this.state[type];

                    if (meaning.meaningCount === undefined) {
                        return;
                    }

                    if (meaning.meaningCount >= 2) {
                        // delete
                        const params = {
                            meaningId: meaning.id,
                        };
                        await Fetch.DELETE("/admin/meaning", params);
                    } else {
                        return;
                    }

                    break;

                case "editEx":
                    const ex = this.state[type];
                    if (ex.exCount === undefined) {
                        return;
                    }
                    if (ex.exCount >= 2) {
                        // delete
                        const params = {
                            exampleSentenceId: ex.id,
                        };
                        await Fetch.DELETE("/admin/ex", params);
                    } else {
                        return;
                    }
                    break;

                default:
                    break;
            }
            this.handleDialog(type, false);
            this.refreshTable();
        } catch (error) {
            console.error(error);
        }
    };

    uploadFileToS3 = async (file) => {
        const fileType = file.type.split("/")[0];
        const data = new FormData();
        switch (fileType) {
            case "audio":
                data.append("file", file);
                break;
            default:
                console.warn("uploadFileToS3::unhandled fileType -", fileType);
                return null;
        }
        try {
            const json = await Fetch.POST("/upload/admin/audio", data);
            return json;
        } catch (err) {
            console.error(err);
            return null;
        }
    };

    onClickPlayAudio = (params) => {
        const { audio_url } = params;
        if (!audio_url) return;
        const url = URL.S3_AUDIO + `/${audio_url}`;
        const audio = new Audio(url);
        audio.play();
    };

    onClickDeleteWord = async (wordId) => {
        try {
            if (
                window.confirm(
                    "단어를 삭제할 경우, 단어와 연관된 북마크, 의미, 예문이 모두 삭제됩니다. 정말 삭제하시겠습니까?"
                )
            ) {
                const params = { wordId };
                const json = await Fetch.DELETE("/admin/word", params);
                if (json.success) {
                    window.alert("삭제가 완료되었습니다.");
                    this.refreshTable();
                } else {
                    window.alert("삭제하는 과정에서 문제가 발생했습니다");
                }
            }
        } catch (error) {
            console.error(error);
            window.alert("삭제하는 과정에서 문제가 발생했습니다");
        }
    };

    configureLoading = async (isShow) => {
        await this.updateState({
            fetchLoading: isShow,
        });
    };

    render() {
        const {
            itemCount,
            data,
            loading,
            pages,
            pageSize,
            filtered,
            sorted,

            editWord,
            editMeaning,
            editEx,
            addWord,
            fetchLoading,
        } = this.state;

        const {
            getData,
            onChangeSearchInput,
            onClickSearch,

            handleDialog,
            onChangeTF,
            onClickEdit,
            onChangeSelect,
            onHandleMeaningBoxInAddWord,
            onChangeMeaningBox,

            onHandleExBoxInAddWord,
            onChangeExBox,

            onClickDelete,
            onClickPlayAudio,
            onClickDeleteWord,
        } = this;
        return (
            <View
                table={this.table}
                itemCount={itemCount}
                data={data}
                loading={loading}
                pages={pages}
                pageSize={pageSize}
                getData={getData}
                filtered={filtered}
                sorted={sorted}
                onChangeSearchInput={onChangeSearchInput}
                onClickSearch={onClickSearch}
                // dialog
                handleDialog={handleDialog}
                onChangeTF={onChangeTF}
                editWord={editWord}
                editMeaning={editMeaning}
                editEx={editEx}
                addWord={addWord}
                onClickEdit={onClickEdit}
                onChangeSelect={onChangeSelect}
                onHandleMeaningBoxInAddWord={onHandleMeaningBoxInAddWord}
                onChangeMeaningBox={onChangeMeaningBox}
                onHandleExBoxInAddWord={onHandleExBoxInAddWord}
                onChangeExBox={onChangeExBox}
                onClickDelete={onClickDelete}
                onClickPlayAudio={onClickPlayAudio}
                onClickDeleteWord={onClickDeleteWord}
                fetchLoading={fetchLoading}
            />
        );
    }
}

export default Container;
