import React from "react";
import ReactTable from "react-table";
import * as CellHelper from "../../Common/Util/CellHelper";

import { Button, TextField } from "@material-ui/core";

const View = (props) => {
    const {
        itemCount,
        table,
        data,
        loading,
        pages,
        pageSize,
        filtered,
    } = props;
    const { getData, onClickDeleteUser } = props;

    const { onChangeSearchInput, onClickSearch } = props;

    const columns = [
        {
            Header: "이메일",
            accessor: "email",
        },
        {
            Header: "닉네임",
            accessor: "nickname",
            Cell: (row) => {
                const user = row.original;
                const nickname = user.nickname;
                if (!nickname) {
                    return <div style={{ color: "red" }}>Nothing</div>;
                }
                return nickname;
            },
        },
        {
            Header: "소셜로그인",
            Cell: (row) => {
                const user = row.original;
                const loginType = user.login_type;
                let loginPlatform = <div style={{ color: "red" }}>Nothing</div>;
                switch (loginType) {
                    case 1:
                        loginPlatform = "카카오";
                        break;
                    case 2:
                        loginPlatform = "네이버";
                        break;
                    case 3:
                        loginPlatform = "애플";
                        break;
                    default:
                        break;
                }
                return loginPlatform;
            },
        },
        {
            Header: "학년",
            Cell: (row) => {
                const user = row.original;
                const ageType = user.age_type;
                let grade = <div style={{ color: "red" }}>Nothing</div>;
                switch (ageType) {
                    case 1:
                        grade = "중3";
                        break;
                    case 2:
                        grade = "고1";
                        break;
                    case 3:
                        grade = "고2";
                        break;
                    case 4:
                        grade = "고3";
                        break;
                    case 5:
                        grade = "기타";
                        break;
                    default:
                        break;
                }
                return grade;
            },
        },
        {
            Header: "인증 여부",
            Cell: (row) => {
                return CellHelper.booleanCellHandler(row.original.is_certified);
            },
        },
        {
            Header: "데일리 학습 단어",
            Cell: (row) => {
                const user = row.original;
                const dailyWord = user.FK_daily_word_DailyWord;
                if (dailyWord) {
                    return dailyWord.amount;
                } else {
                    return <div style={{ color: "red" }}>Nothing</div>;
                }
            },
        },
        CellHelper.dateCell({ key: "createdAt", header: "가입일" }),
        CellHelper.dateCell({ key: "updatedAt", header: "수정일" }),
        CellHelper.deleteCell({
            page: "User",
            title: "유저 삭제",
            eventHanlder: onClickDeleteUser,
        }),
    ];

    return (
        <div className="Users">
            <div className="Users_Wrap">
                <h2 className="Users__Title">{`유저 목록(총 ${itemCount}명)`}</h2>
                <TextField
                    style={{
                        marginBottom: "15px",
                    }}
                    error={false}
                    margin="dense"
                    name="email"
                    type="text"
                    label="email"
                    onChange={(e) => {
                        onChangeSearchInput(e);
                    }}
                />
                <Button
                    variant="contained"
                    style={{
                        backgroundColor: "#2ad2c9",
                        color: "white",
                        marginLeft: "5px",
                        height: "30px",
                        width: "50px",
                    }}
                    onClick={() => {
                        onClickSearch();
                    }}
                >
                    검색
                </Button>
            </div>
            <ReactTable
                ref={table}
                columns={columns}
                manual
                filtered={filtered}
                data={data}
                pages={pages}
                loading={loading}
                onFetchData={getData}
                defaultPageSize={pageSize}
                className="-striped -highlight"
            />
        </div>
    );
};

export default View;
