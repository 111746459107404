import React, { Component } from "react";
import sha256 from "../../Common/Util/SHA256";
// import * as Fetch from "../../Common/Util/Fetch";
import "./index.scss";
import * as LoginHelper from "../../Common/Util/LoginHelper";
import * as Fetch from "../../Common/Util/Fetch";

export default class Login extends Component {
    constructor(props) {
        super(props);
        this.state = { email: "", password: "", loginInfo: "" };
    }
    componentDidMount() {
        if (LoginHelper.isLogin()) {
            this.props.history.replace({
                pathname: "/users",
            });
        }
    }

    handleOnChange = (e) => {
        this.setState({
            [e.target.name]: e.target.value,
        });
    };

    handleSubmit = async (e) => {
        e.preventDefault();
        const { email, password } = this.state;
        if (!email || email.length < 8) {
            this.setState({
                loginInfo: "올바른 이메일을 입력하세요",
            });
            return;
        }
        if (!password || password.length < 6) {
            this.setState({
                loginInfo: "올바른 패스워드를 입력하세요",
            });
            return;
        }

        const params = { email, password: sha256(password) };

        let adminLogin;
        try {
            adminLogin = await Fetch.POST("/login/admin", params);
        } catch (error) {
            return;
        }

        this.setState(
            {
                loginInfo: "",
            },
            () => {
                const user = {
                    level: adminLogin.adminUser.level,
                    token: adminLogin.token,
                };
                LoginHelper.setUser(user);
                this.props.history.replace({
                    pathname: "/users/",
                });
            }
        );
    };

    render() {
        const { email, password, loginInfo } = this.state;
        return (
            <div className="Login__Page">
                <h1 className="Login__Title">조정식 VOCA 앱 관리자</h1>
                <div className="Login__Form">
                    <form onSubmit={this.handleSubmit}>
                        <input
                            type="text"
                            name="email"
                            placeholder="ID"
                            value={email}
                            onChange={this.handleOnChange}
                        />
                        <input
                            type="password"
                            name="password"
                            autoComplete={"false"}
                            placeholder="Password"
                            value={password}
                            onChange={this.handleOnChange}
                        />
                        <input
                            className="Login__Submit"
                            type="submit"
                            value="LOGIN"
                        />
                    </form>
                    <p className="Login__Warn">{loginInfo}</p>
                </div>
            </div>
        );
    }
}
