import {
    TextField,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    Button,
    InputLabel,
    FormControl,
    NativeSelect,
    Tooltip,
} from "@material-ui/core";
import exPreview from "../../../Asset/Image/exampleSentencePreview.png";
import Loading from "../../../Asset/Image/loading.gif";

import MeaningBox from "./MeaningBox";

const WordDialog = (props) => {
    const {
        handleDialog,
        onChangeTF,
        word,
        onClickEdit,
        onChangeSelect,
        onHandleMeaningBoxInAddWord,
        onChangeMeaningBox,
        onHandleExBoxInAddWord,
        onChangeExBox,
        type,
        onClickPlayAudio,
        fetchLoading,
    } = props;
    // const type = "word";

    const chapters = [
        {
            id: 1,
            title: "생성",
        },
        {
            id: 2,
            title: "구성",
        },
        {
            id: 3,
            title: "감소",
        },
        {
            id: 4,
            title: "소멸",
        },
        {
            id: 5,
            title: "분산",
        },
        {
            id: 6,
            title: "출발",
        },
        {
            id: 7,
            title: "지속하다",
        },
        {
            id: 8,
            title: "선을 벗어나다",
        },
        {
            id: 9,
            title: "선에 닿다 ",
        },
        {
            id: 10,
            title: "선을 넘어가다",
        },
        {
            id: 11,
            title: "선을 뛰어넘다",
        },
        {
            id: 12,
            title: "선 아래에 머무르다",
        },
        {
            id: 13,
            title: "선 아래에 두다",
        },
        {
            id: 14,
            title: "선에 미치지 못하다",
        },
        {
            id: 15,
            title: "변화",
        },
        {
            id: 16,
            title: "선을 긋다",
        },
        {
            id: 17,
            title: "분명한/확실한",
        },
        {
            id: 18,
            title: "구분선이 없는",
        },
        {
            id: 19,
            title: "쥐다/잡다",
        },
        {
            id: 20,
            title: "주다/건네다",
        },
        {
            id: 21,
            title: "힘을 가하다",
        },
        {
            id: 22,
            title: "상호작용",
        },
        {
            id: 23,
            title: "보다",
        },
        {
            id: 24,
            title: "보이다",
        },
        {
            id: 25,
            title: "그리다",
        },
        {
            id: 26,
            title: "정도/규모",
        },
        {
            id: 27,
            title: "비중",
        },
        {
            id: 28,
            title: "부분/구성",
        },
        {
            id: 29,
            title: "시간/기간/시기",
        },
        {
            id: 30,
            title: "부정적 감정",
        },
        {
            id: 31,
            title: "긍정적 감정",
        },
        {
            id: 32,
            title: "부정적 태도",
        },
        {
            id: 33,
            title: "긍정적 태도",
        },
        {
            id: 34,
            title: "특징",
        },
        {
            id: 35,
            title: "법/범죄",
        },
        {
            id: 36,
            title: "정치",
        },
        {
            id: 37,
            title: "소비 /자산",
        },
        {
            id: 38,
            title: "경제",
        },
        {
            id: 39,
            title: "문화/예술",
        },
        {
            id: 40,
            title: "역사/종교",
        },
        {
            id: 41,
            title: "과학",
        },
        {
            id: 42,
            title: "소통",
        },
    ];

    if (!word) return <></>;

    const isValidWord = word.text_eng !== undefined && word.text_eng !== "";
    const isValidAudioSymbol =
        word.audio_symbol !== undefined && word.audio_symbol !== "";
    const isValidChapter =
        word.FK_chapter !== undefined && word.FK_chapter !== "";

    let isValidMeanings = true;
    let isValidExs = true;
    let isValidExistMeaning = true;
    let isValidExistEx = true;

    if (word.Meanings.length > 0) {
        for (let i = 0; i < word.Meanings.length; i++) {
            const _meaning = word.Meanings[i];

            if (_meaning.isValid) {
                if (_meaning.ExampleSentences.length > 0) {
                    for (let j = 0; j < _meaning.ExampleSentences.length; j++) {
                        const ex = _meaning.ExampleSentences[j];
                        if (!ex.isValid) {
                            isValidExs = false;
                            break;
                        }
                    }
                } else {
                    isValidExistEx = false;
                }
            } else {
                isValidMeanings = false;
                break;
            }
        }
    } else {
        isValidExistMeaning = false;
    }
    let errorMessage = "";
    if (!isValidWord || !isValidAudioSymbol || !isValidChapter) {
        errorMessage = "단어 정보를 누락했습니다.";
    } else if (!isValidExistMeaning) {
        errorMessage = "최소 하나 이상의 의미가 있어야 합니다.";
    } else if (!isValidExistEx) {
        errorMessage = "최소 하나 이상의 예문이 있어야 합니다.";
    } else if (!isValidMeanings) {
        errorMessage = "의미 정보를 누락했습니다.";
    } else if (!isValidExs) {
        errorMessage = "예문 정보를 누락했습니다.";
    } else if (fetchLoading) {
        errorMessage = "단어를 추가가 끝날 때 까지 기다려주세요.";
    }

    const isValid =
        isValidWord &&
        isValidAudioSymbol &&
        isValidChapter &&
        isValidMeanings &&
        isValidExs &&
        isValidExistMeaning &&
        isValidExistEx;

    return (
        <Dialog
            fullScreen
            open={word !== undefined}
            onClose={() => {
                handleDialog(type, false);
            }}
            aria-labelledby="form-dialog-title"
        >
            <div style={{ maxWidth: "1000px" }}>
                <DialogTitle id="form-dialog-title">단어 추가</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        입력한 단어는 가장 마지막에 추가됩니다.
                    </DialogContentText>
                    <DialogContentText>
                        하나의 단어는 최소한 하나의 의미와 하나의 예문이
                        필요합니다.
                    </DialogContentText>
                    <Tooltip
                        title={
                            <img
                                className="exbox-previewImage"
                                src={exPreview}
                                alt=""
                            />
                        }
                        placement="right"
                        arrow
                    >
                        <div className="exbox-preview">미리보기</div>
                    </Tooltip>

                    <TextField
                        error={!isValidWord}
                        autoFocus
                        margin="dense"
                        name="text_eng"
                        label="단어"
                        type="text"
                        fullWidth
                        defaultValue={word.text_eng}
                        onChange={(e) => {
                            onChangeTF(type, e);
                        }}
                    />
                    <TextField
                        error={!isValidAudioSymbol}
                        autoFocus
                        margin="dense"
                        name="audio_symbol"
                        label="음성기호"
                        type="text"
                        fullWidth
                        defaultValue={word.audio_symbol}
                        onChange={(e) => {
                            onChangeTF(type, e);
                        }}
                    />

                    <FormControl className="">
                        <InputLabel
                            shrink
                            htmlFor="chapter-native-label-placeholder"
                        >
                            챕터
                        </InputLabel>
                        <NativeSelect
                            error={!isValidChapter}
                            value={word.FK_chapter}
                            onChange={(e) => {
                                onChangeSelect(type, e);
                            }}
                            inputProps={{
                                name: "FK_chapter",
                                id: "age-native-label-placeholder",
                            }}
                        >
                            {[
                                <option key={"first key"} value={""}>
                                    챕터 선택
                                </option>,
                                ...chapters.map((chapter, index) => (
                                    <option
                                        key={`select-${index}`}
                                        value={chapter.id}
                                    >
                                        {chapter.title}
                                    </option>
                                )),
                            ]}
                        </NativeSelect>
                    </FormControl>
                    <div className="WordDialog_audio">
                        <div style={{ color: "gray" }}>오디오 URL (선택):</div>
                        <div
                            className="WordDialog_ad-editBtn"
                            onClick={() => {
                                document
                                    .getElementById(`input-wordAudio`)
                                    .click();
                            }}
                        >
                            {word.audio_url ? "수정" : "업로드"}
                        </div>
                        <div
                            className="WordDialog_ad-fileName"
                            onClick={() => {
                                onClickPlayAudio({
                                    audio_url: word.audio_url,
                                });
                            }}
                        >
                            {word.audio_url ? word.audio_url : ""}
                        </div>
                        <input
                            id={`input-wordAudio`}
                            type="file"
                            name="file"
                            accept=".mp3"
                            onChange={(e) => {
                                onChangeTF(type, e);
                            }}
                            hidden
                        />
                    </div>
                    <TextField
                        error={false}
                        autoFocus
                        margin="dense"
                        name="additionalWords"
                        label="추가어휘 (선택)"
                        type="text"
                        fullWidth
                        defaultValue={word.additionalWords}
                        onChange={(e) => {
                            onChangeTF(type, e);
                        }}
                    />
                    <TextField
                        error={false}
                        autoFocus
                        margin="dense"
                        name="additionalMeanings"
                        label="추가어휘 뜻 (선택)"
                        type="text"
                        fullWidth
                        defaultValue={word.additionalMeanings}
                        onChange={(e) => {
                            onChangeTF(type, e);
                        }}
                    />

                    {word.Meanings.map((meaning, index) => {
                        return (
                            <MeaningBox
                                type={type}
                                key={index}
                                meaningBoxIndex={index}
                                meaning={meaning}
                                onChangeMeaningBox={onChangeMeaningBox}
                                onHandleExBoxInAddWord={onHandleExBoxInAddWord}
                                onChangeExBox={onChangeExBox}
                                onClickPlayAudio={onClickPlayAudio}
                            />
                        );
                    })}

                    <Button
                        onClick={async () => {
                            const addedIndex = await onHandleMeaningBoxInAddWord(
                                type,
                                true
                            );
                            onHandleExBoxInAddWord(type, true, addedIndex);
                        }}
                        color={"primary"}
                    >
                        의미 추가
                    </Button>
                    <Button
                        onClick={() => {
                            onHandleMeaningBoxInAddWord(type, false);
                        }}
                        color={"primary"}
                    >
                        의미 삭제
                    </Button>
                </DialogContent>
                <DialogActions>
                    <DialogContentText style={{ color: "red" }}>
                        {errorMessage}
                    </DialogContentText>
                    <Button
                        onClick={() => {
                            if (fetchLoading) return;
                            handleDialog(type, false);
                        }}
                    >
                        취소
                    </Button>
                    {fetchLoading && (
                        <img
                            style={{
                                width: "30px",
                            }}
                            src={Loading}
                            alt="loading"
                        />
                    )}
                    <Button
                        variant="contained"
                        onClick={() => {
                            if (!isValid) return;
                            if (fetchLoading) return;
                            onClickEdit(type);
                        }}
                        color={isValid ? "primary" : "secondary"}
                    >
                        추가
                    </Button>
                </DialogActions>
            </div>
        </Dialog>
    );
};

export default WordDialog;
