import React from "react";
import View from "./View.js";
import * as Fetch from "../../Common/Util/Fetch.js";
import ClabComponent from "../../Common/Util/ClabComponent";

export class Container extends ClabComponent {
    constructor(props) {
        super(props);
        this.table = React.createRef();
        this.state = {
            data: [],
            pages: null,
            loading: true,
            itemCount: 0,
            pageSize: 20,

            filtered: [],
        };
    }

    componentDidMount() {}

    getData = async (state, instance) => {
        try {
            await this.updateState({ loading: true });

            let { page, pageSize, sorted, filtered } = state;
            const params = {
                page,
                pageSize,
                sorted: JSON.stringify(sorted),
                filtered: JSON.stringify(filtered),
            };
            const users = await Fetch.GET("/admin/users", params);

            await this.updateState({
                data: users.rows,
                pages: Math.ceil(users.count / pageSize),
                loading: false,
                itemCount: users.count,
            });
        } catch (error) {
            console.error(error);
        }
    };

    refreshTable = () => {
        this.getData(this.table.current.state);
    };

    onClickDeleteUser = async (id) => {
        if (window.confirm("정말 삭제하시겠습니까?")) {
            await this.deleteUser(id);
        }
    };

    deleteUser = async (id) => {
        try {
            await Fetch.DELETE("/admin/user", { destoryUserId: id });
            this.refreshTable();
        } catch (error) {
            console.error(error);
        }
    };

    onChangeSearchInput = (e) => {
        const name = e.target.name;
        const value = e.target.value;

        const filterObj = {
            id: name,
            value,
        };

        const { filtered } = this.state;
        if (filtered.length === 0) {
            filtered.push(filterObj);
        } else {
            filtered[0] = filterObj;
        }

        this.setState({
            filtered,
        });
    };

    onClickSearch = () => {
        this.refreshTable();
    };

    render() {
        const {
            itemCount,
            data,
            loading,
            pages,
            pageSize,
            filtered,
        } = this.state;
        const {
            getData,
            onClickDeleteUser,
            onChangeSearchInput,
            onClickSearch,
        } = this;
        return (
            <View
                table={this.table}
                itemCount={itemCount}
                data={data}
                loading={loading}
                pages={pages}
                filtered={filtered}
                pageSize={pageSize}
                getData={getData}
                onClickDeleteUser={onClickDeleteUser}
                onChangeSearchInput={onChangeSearchInput}
                onClickSearch={onClickSearch}
            />
        );
    }
}

export default Container;
