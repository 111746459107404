const subdomain = "api.";
const isRelease = true;
export const URL = {
    API_SERVER: isRelease
        ? `https://${subdomain}clabvoca.net`
        : "http://localhost:3000",

    S3_AUDIO: "https://s3.ap-northeast-2.amazonaws.com/clabvoca.tk/audio",
};

export const TermsText = {
    TermsOfService: `
Terms of service

제1조 (목적)
이 약관은 C-LAB(이하 “회사”)이 제공하는 온라인으로 제공하는 디지털 서비스를 이용함에 있어 회사와 회원간의 권리, 의무 및 책임사항과 절차 등을 규정함을 목적으로 합니다.

제2조 (정의)
이 약관에서는 용어를 다음과 같이 정의하여 사용합니다.
1. “서비스”란 회원의 단말기(모바일, 태블릿PC 등 각종 유무선 장치를 포함)를 통하여 회사가 제공하는 ‘정식 VOCA APP 서비스’ 일체를 말합니다.2. “회원”이란 서비스에 접속하여 이 약관에 따라 회사와 이용계약을 체결함으로써 서비스를 이용하는 고객 여러분을 말합니다.3. “닉네임”이란 회원의 식별과 서비스 제공을 위하여 회원이 설정하고 회사가 승인하여 등록된 문자 또는 숫자의 조합을 말합니다.4. “시리얼코드”라 함은 서비스 이용 시, 아이디와 일치되는 회원임을 확인하고 회원 개인정보보호를 위하여 회사가 승인하여 등록된 문자와 숫자의 조합을 말합니다.5. “전자우편(Email)”이라 함은 인터넷을 통한 우편 혹은 전기적 매체를 이용한 우편을 말합니다.6. “컨텐츠”란 정보통신망이용촉진 및 정보보호 등에 관한 법률 제2조 제1항 제1호의 규정에 의한 정보통신망에서 사용되는 부호·문자·음성·음향·이미지 또는 영상 등으로 표현된 자료 또는 정보로서, 그 보존 및 이용에 있어서 효용을 높일 수 있도록 전자적 형태로 제작 또는 처리된 것을 말합니다.

제3조 (약관의 게시와 개정)
1. 회사는 본 약관의 내용을 회원이 쉽게 알 수 있도록 앱 초기화면 및 마이 페이지에 게시합니다.
2. 회사는 필요에 따라 “약관의 규제에 관한 법률”, “정보통신망이용촉진 및 정보보호 등에 관한 법률” 등 관련 법령을 위반하지 않는 범위 내에서 이 약관을 개정할 수 있습니다.3. 회사가 약관을 개정하는 경우 적용일자 및 개정사항을 명시하여 적용일 14일 전에 앱 마이 페이지에 공지합니다.4. 공지일로부터 14일 이내에 회원이 명시적으로 거부하지 않는 경우 개정 약관에 동의하신 것으로 봅니다.5. 회원이 개정에 동의하지 않는다는 의사를 표시한 경우 회사는 개정된 약관을 적용할 수 없으며, 회원은 개정 약관의 효력 발생일부터 서비스를 이용하실 수 없습니다.

제4조 (약관의 해석)
1. 이 약관에서 정하지 않은 사항이나 해석에 대하여는 관련법령 또는 상관례에 따릅니다.2. “회원”이 “회사”와 서비스의 이용에 대한 별도의 계약을 체결할 경우 해당 “회원”에 대하여는 개별 계약이 본 약관에 우선하여 적용됩니다.

제5조 (이용계약의 체결)
1. 서비스 이용계약은 회원이 되고자 하는 사람(이하 “가입신청자”)이 약관에 동의하고 가입을 신청한 다음 회사가 이를 수락함으로써 체결됩니다. 필요한 경우 회사는 본인확인 기관을 통한 실명확인 및 본인인증을 요청할 수 있습니다.2. 가입신청자는 가입신청 시 진실한 정보를 기재하여야 하며, 허위의 정보를 기재함으로 인한 불이익 및 법적 책임은 가입신청자에게 있습니다.3. 정보통신망 이용 촉진 및 정보보호 등에 관한 법률의 개인정보지침 제 22조 서비스 이용자가 만 14세 미만의 개인정보 수정 시 법정대리인(부모님)의 동의 필요하며, 만 14세 미만 회원은 부모님의 성명과 주민등록번호•휴대폰으로 부모님 동의를 받은 후, 회원가입이 가능합니다.4. 가입신청자가 이전에 회원자격을 상실한 적이 있는 경우, 허위의 명의 또는 타인의 명의를 이용한 경우, 기타 승낙이 불가능한 사유가 있는 경우 승낙이 거부될 수 있습니다.5. 회사는 서비스 관련설비의 여유 기타 기술상 또는 업무상의 이유로 승낙을 유보할 수 있으며, 그 결과를 가입신청자에게 알려드립니다.

제6조 (회원정보의 변경)
1. “회원"의 "닉네임"에 관한 관리책임은 "회원"에게 있으며, 이를 제3자가 이용하도록 하여서는 안 됩니다.
2. 회원은 서비스 내 마이 페이지 화면을 통하여 본인의 개인정보를 열람할 수 있으나, 서비스 관리를 위하여 닉네임, 이메일 주소는 수정할 수 없습니다.
3. 가입신청 시에 기재한 회원정보에 변동이 생긴 경우 이를 수정하거나 회사에 통지하여야 하며 미수정 또는 통지하지 않음으로 인한 불이익에 대하여 회사는 책임지지 않습니다.
4. 회원이 자신의 ID 또는 비밀번호를 도난 당하거나 제3자가 사용하고 있음을 인지한 경우 즉시 회사에 통지하고 회사의 안내를 따라야 합니다.
5. 제4항의 경우 회사에 통지하지 않거나, 안내에 따르지 않아 발생한 불이익에 대하여 회사는 책임지지 않습니다.

제7조 (서비스 내용 및 이용 요금)
1. 정식 VOCA 서비스는 메가스터디(www.megastudy.net) 온라인에서 ALL TIME 조정식 VOCABULARY 교재를 구매한 회원에게만 제공되는 서비스입니다.2. 회사가 제공하는 서비스는 무료입니다.3. 무선 서비스 이용 시 발생하는 데이터 통신료는 별도이며, 가입한 각 이동통신사의 정책에 따릅니다.

제8조 (서비스의 변경)
1. 회사는 운영상, 기술상의 필요에 따라 제공하는 서비스의 전부 또는 일부를 변경할 수 있으며, 이에 대하여 약관에 다른 규정이 없는 한 회원에게 별도의 보상을 하지는 않습니다.2. 서비스를 변경하는 경우 변경사유 및 일자, 변경내용을 변경 7일 전 이메일로 안내합니다. 단, 변경사유 또는 내용을 구체적으로 공지하기 어려운 경우에는 그 이유를 밝힙니다.

제9조 (서비스의 일시 중단)
1. 회사는 서비스관련설비 보수점검, 교체 및 고장, 통신두절 등 기술상 업무상의 이유로 서비스의 제공을 일시적으로 중단할 수 있습니다. 이 경우 사전에 통지함을 원칙으로 하지만, 부득이한 사유가 있는 경우 사후에 통지할 수 있습니다.2. 서비스 일시 중단은 이메일 또는, 서비스 웹사이트에 게시하는 방법으로 통지합니다.

제10조 (회원의 계약해지, 이용 중지요청 등)
1. 회원은 언제든지 서비스 내 1:1 문의하기 메뉴 및 서비스 웹사이트 내 교재 환불을 통하여 서비스 이용계약 해지를 신청할 수 있으며 회사는 이를 즉시 처리합니다.2. 해지로 인하여 제휴 컨텐츠 이용과 관련한 정보가 삭제됨에 따라 회원에게 발생하는 손해에 대해 회사는 책임지지 않습니다.3. 회원은 이메일 통보 등 정해진 절차를 거쳐 서비스의 이용중지를 요청하실 수 있습니다.

제11조 (회사의 계약해지, 이용제한 등)
1. 회사는 다음의 경우 사전통보 없이 해당 회원과의 이용계약을 해지하여 회원 자격을 상실시킬 수 있습니다.i. 회원이 사망한 경우ii. 타인의 개인정보 또는 모바일 기기를 도용한 경우iii. 회원이 가입 시 작성한 개인정보가 허위임이 밝혀진 경우iv. 서비스 외에서 회사, 운영자, 임직원 등을 사칭하는 경우v. 무단으로 회사의 클라이언트 프로그램을 변경하거나 서버를 해킹하는 등 시스템을 위협한 경우vi. 허위사실 유포, 위계 및 기타 방법으로 회사의 명예 또는 신용을 훼손하거나 업무를 방해한 경우vii. 서비스에 관한 스팸성 홍보 활동을 하는 경우
viii. 기타 본 약관 상의 의무 또는 법령에 위반되는 행위를 한 경우2. 위 i호부터 viii호의 사유로 자격상실 통보를 받은 회원의 이용을 제한할 수 있으며, 회원은 이의를 제기할 수 없습니다.

제12조 (회사의 의무)
1. 회사는 관련 법령 또는 이 약관을 위반하지 않으며, 계속적이고 안정적으로 서비스를 제공하기 위하여 최선을 다합니다.2. 회사는 회원의 개인정보 보호를 위하여 보안시스템을 갖추어야 하며 개인정보취급방침을 공시하고 준수하겠습니다.3. 회사는 서비스 이용과 관련한 회원의 의견이나 불만사항 등이 정당하다고 인정할 경우 이를 처리하여야 합니다. 처리된 결과는 이메일을 통해 회원에게 통보합니다.

제13조 (회원의 의무)
회원은 서비스 이용과 관련하여 다음의 행위를 하여서는 안됩니다.i. 서비스 이용 관련 제반 신청 및 변경행위 시 허위내용의 등록ii. 서비스 내 게시된 각종 정보의 무단 변경, 삭제 등 훼손iii. 일체의 가공행위를 통해 서비스를 분해, 변경, 모방하는 행위iv. 회사 기타 제3자의 지적재산권을 포함하여 권리를 침해하는 행위v. 다른 회원의 개인정보 수집하거나 명예를 손상하는 행위vi. 회사의 동의 없이 광고를 전송하거나 외설, 폭력적인 정보 등을 노출하는 행위vii. 기타 약관상의 의무를 불이행하는 행위viii. 기타 불법, 부당한 행위

제14조 (저작권의 귀속 및 이용제한)
1. 회사의 상표, 로고, 제공하는 서비스에 관한 지적재산권 등의 권리는 회사에 귀속합니다.2. 회원은 서비스를 이용함으로써 얻은 정보를 회사의 사전승낙 없이 복제, 송신, 출판, 배포, 방송 기타 방법에 의하여 영리목적으로 이용하거나 제3자에게 이용하도록 하여서는 안됩니다.

제15조 (서비스관련 분쟁해결)
1. 회사는 서비스 이용과 관련한 회원의 의견이나 불만사항을 신속하게 처리합니다. 단, 신속한 처리가 곤란한 경우에는 그 사유와 처리일정을 통보하여 드립니다.2. 회사와 회원간에 발생한 분쟁은 전자문서 및 전자거래 기본법에 의해 설치된 전자문서·전자거래분쟁조정위원회의 조정절차에 의해 해결할 수 있습니다.

제16조 (서비스의 종료)
1. 회사는 서비스를 종료하고자 하는 날로부터 3개월 이전에 이 약관 제3조 3항의 방법으로 회원에게 알립니다.2. 종료 통지일로부터 서비스 종료일까지 서비스의 일부가 제한될 수 있습니다.

제17조 (준거법 및 합의관할)
1. 회사와 회원간에 제기된 법적 분쟁은 대한민국 법을 준거법으로 합니다.2. 회사와 회원간의 분쟁에 관한 소송은 서울중앙지방법원 또는 민사소송법 상의 관할법원에 제소합니다.

제18조 (개인정보보호의무)
회사는 관련 법령이 정하는 바에 따라서 회원 등록정보를 포함한 개인정보를 보호하기 위하여 노력합니다. 이에 관해서는 관련 법령 및 회사의 “개인정보취급방침”에 의하며, 회원이 늘 확인하실 수 있도록 웹사이트를 통해 공시하고 있습니다.

부칙
1. 이 약관은 2021년 4월 22일부터 적용됩니다.
    `,

    PrivacyPolicy: `
Privacy Policy

‘C-LAB’ (이하 ‘회사’라 함)는 개인정보보호법, 통신비밀보호법, 전기통신사업법, 정보통신망 이용촉진 및 정보보호 등에 관한 법률 등을 준수하고 있습니다.
 회사의 개인정보처리방침의 주요 내용은 다음과 같습니다. 

개인정보의 처리 목적
서비스 제공에 관한 계약 이행 및 서비스 제공
콘텐츠 제공, 본인인증, 고객상담
회원 관리
회원제 서비스 이용에 따른 본인확인, 개인 식별, 불량 회원의 부정 이용 방지와 비인가 사용 방지, 가입 의사 확인, 연령 확인, 불만처리 등 민원처리, 고지사항 전달
마케팅 및 광고에 활용
신규 서비스 개발 및 특화, 이벤트 등 광고성 정보 전달, 서비스 웹사이트(메가스터디교육)의 홍보를 포함한 이벤트 등 광고성 정보 전달
기타
가입 횟수 제한, 분쟁 조정을 위한 기록 보존, 기타 통계자료 산출
처리하는 개인정보의 항목
회사는 회원가입, 고객 상담, 각종 서비스 제공을 위하여 다음과 같이 최소한의 개인정보를 수집 또는 처리하고 있습니다.
필수항목 - 이름, 닉네임, 이메일 주소, 학년, 네이버/카카오 식별값(아이디)
서비스 이용과정이나 사업 처리 과정에서 생성되어 수집되는 정보
컨텐츠 사용 기록 : 모바일 기기의 UUID, MAC 주소
부정사용 기록 : 접속 IP 정보
개인정보의 처리 및 보유 기간
개인정보의 처리 및 보유기간은 다음과 같습니다.

ο 보유항목: 처리항목과 같습니다. 
ο 보유근거: 이용약관 및 개인정보처리방침 
ο 보유기간: 이용계약에 따른 회원자격이 유지되는 기간 동안
휴면회원 분리 및 보관 : 회사는 관련법령에 의거 1년간 서비스를 이용하지 않은 경우 해당 이용자의 개인정보(이름, 본인인증정보, 아이디, 비밀번호, 본인인증 이외의 휴대전화번호)를 안전하게 분리하여 보관합니다.
관련 법률에 의한 경우
통신비밀보호법에 따른 통신사실확인자료 : 3개월 (통신비밀보호법)
개인정보의 제3자 제공
회사는 이용자의 개인정보를 원칙적으로 제공하지 않으나, 이용자의 동의, 법률의 특별한 규정 등에 해당하는 경우 이용자의 개인정보를 제3자에게 제공할 수 있습니다.
이용자와 법정대리인의 권리 행사 방법
이용자 및 법정대리인은 언제든지 자신의 개인정보와 관련하여 열람, 오류 정정, 삭제, 처리 정지 요구 등과 같은 권리를 행사할 수 있습니다.
제1항에 따른 권리는 서면, 이메일, 전화 통화 등을 이용하여 행사하실 수 있으며 회사는 이에 대해 지체 없이 조치하여야 합니다.
이용자가 개인정보의 오류 등에 대해 정정 또는 삭제를 요구한 경우에는 회사는 정정 또는 삭제를 완료할 때까지 당해 개인정보를 이용하거나 제공하지 않습니다.
개인정보의 파기
회사는 원칙적으로 이용자가 제공한 개인정보 보유기간이 경과된 경우, 개인정보의 처리 목적 달성, 해당 서비스의 폐지 등 그 개인정보가 불필요하게 된 사유가 발생한 경우 5일 이내에 지체없이 해당 개인정보를 파기합니다. 그에 대한 절차 및 방법은 다음과 같습니다.
파기절차
이용자가 제공한 개인정보는 관련 법령에 의하여 일정기간 보존하여야 할 개인정보는 별도 DB로 옮겨 일정기간 보존 된 후 파기합니다. 이 때, DB로 옮겨진 개인정보는 법률에 의한 경우가 아니고서는 다른 목적으로 이용되지 않습니다.
파기방법
전자적 파일 형태의 개인정보는 기록을 재생할 수 없는 기술적인 방법을 사용하여 파기합니다.
문서로 출력된 개인정보는 파쇄기로 파쇄하거나 소각을 통하여 파기합니다.
개인정보 자동수집 장치의 설치 운영 및 그 거부에 관한 사항
모바일과 태블릿PC용 앱을 통해 서비스 이용하며, 이용자에게 개인정보 수집 및 이용 동의를 받은 범위 내에서 단말기정보 등에 접근한다는 것을 고지하고 승인을 얻습니다. 서비스 제공을 위한 앱의 접근 권한은 필수 또는 선택으로 요청하고 있으며, 단말기 내 "설정" 메뉴를 통하여 이용자가 직접 권한을 변경할 수 있습니다. 앱 권한에 대한 자세한 사항은 애플리케이션 스토어를 통해 확인하실 수 있습니다. 
스마트폰 상에서 앱을 삭제하더라도 이용자의 회원계정은 유지되므로, 회원탈퇴를 원하실 경우 앱 내 고객센터로 연락하여 주시기 바랍니다.

개인정보보호책임자
회사는 개인정보처리에 관한 업무를 총괄해서 책임지고, 개인정보 처리와 관련한 정보 주체의 불만처리 및 피해구제 등을 위하여 아래와 같이 개인정보 보호책임자를 지정하고 있습니다.
개인정보보호책임자 : 박현준 
개인정보보호담당자 : 박현준
전화번호 : 010-2709-3805 
이메일 :  academey@gmail.com
개인정보의 안정성 확보조치
회사는 개인정보보호법 제29조에 따라 다음과 같이 안정성 확보에 필요한 기술적, 관리적 및 물리적 조치를 하고 있습니다.
개인정보 취급 담당자의 최소화 및 교육 개인정보를 취급하는 담당자를 지정하고 최소화하여 개인정보를 관리하는 대책을 시행하고 있습니다.
내부관리계획의 수립 및 시행
개인정보의 안전한 처리를 위하여 내부관리계획을 수립하고 시행하고 있습니다.
해킹 등에 대비한 기술적 대책
회사는 해킹이나 컴퓨터 바이러스 등에 의한 개인정보 유출 및 훼손을 막기 위하여 보안프로그램을 설치하고 주기적인 갱신·점검을 하며 외부로부터 접근이 통제된 구역에 시스템을 설치하고 기술적/물리적으로 감시 및 차단하고 있습니다.
개인정보의 암호화
이용자의 개인정보는 암호화 되어 저장 및 관리되고 있습니다. 또한 중요한 데이터는 저장 및 전송 시 암호화하여 사용하는 등 별도 보안 기능을 사용하고 있습니다.
개인정보에 대한 접근 제한
개인정보를 처리하는 데이터베이스시스템에 대한 접근권한의 부여, 변경, 말소를 통하여 개인정보에 대한 접근통제를 위하여 필요한 조치를 하고 있으며 침입차단시스템을 이용하여 외부로부터의 무단 접근을 통제하고 있습니다.
개인정보처리방침 변경에 관한 사항
회사는 개인정보처리방침을 개정하는 경우 최소 7일 전에 이메일을 통하여 고지할 것 입니다.
개인정보에 관한 민원서비스
기타 개인정보침해에 대한 신고나 상담이 필요하신 경우에는 아래 기관에 문의하시기 바랍니다. 1) 개인정보침해신고센터 (privacy.kisa.or.kr / 국번없이 118)
2) 개인정보분쟁조정위원회 (www.kopico.go.kr / 1833-6972)
3) 대검찰청 사이버수사과 (www.spo.go.kr / 국번없이 1301)
4) 경찰청 사이버안전국 (cyberbureau.police.go.kr / 국번없이 182)
 
본 개인정보처리방침에서 규정되지 않은 사항은 관계법령의 범위 내에서 당사의 이용약관을 우선적으로 적용합니다.
개인정보처리방침 공고일자 : 2021년 4월 22일
개인정보처리방침 시행일자 : 2021년 4월 22일

    `,
};
