import {
    TextField,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    Button,
} from "@material-ui/core";

const ExDialog = (props) => {
    const {
        handleDialog,
        onChangeTF,
        editEx,
        onClickEdit,
        onClickDelete,
        onClickPlayAudio,
    } = props;
    const type = "editEx";

    if (!editEx) return <></>;
    let isValidWrongAnswers = false;
    try {
        const splited = editEx.wrong_answers.split(",");
        isValidWrongAnswers = splited.length >= 3 && !splited.includes("");
    } catch {
        isValidWrongAnswers = false;
    }

    const isValidTextEng =
        editEx.text_eng !== undefined && editEx.text_eng !== "";
    const isValidTextKor =
        editEx.text_kor !== undefined && editEx.text_kor !== "";
    const isValidTextHighlight =
        editEx.text_highlight !== undefined &&
        editEx.text_highlight !== "" &&
        editEx.text_highlight !== " " &&
        editEx.text_eng.includes(editEx.text_highlight);

    const isValid =
        isValidWrongAnswers &&
        isValidTextEng &&
        isValidTextKor &&
        isValidTextHighlight;

    return (
        <Dialog
            open={editEx !== undefined}
            onClose={() => {
                handleDialog(type, false);
            }}
            aria-labelledby="form-dialog-title"
        >
            <DialogTitle id="form-dialog-title">예문 수정</DialogTitle>
            <DialogContent>
                <DialogContentText>
                    수정할 예문 정보를 입력한 후 수정 버튼을 눌러주세요
                </DialogContentText>

                <TextField
                    error={!isValidTextEng}
                    autoFocus
                    margin="dense"
                    name="text_eng"
                    label="예문"
                    type="text"
                    fullWidth
                    defaultValue={editEx.text_eng}
                    onChange={(e) => {
                        onChangeTF(type, e);
                    }}
                />
                <TextField
                    error={!isValidTextKor}
                    autoFocus
                    margin="dense"
                    name="text_kor"
                    label="의미"
                    type="text"
                    fullWidth
                    defaultValue={editEx.text_kor}
                    onChange={(e) => {
                        onChangeTF(type, e);
                    }}
                />
                <TextField
                    error={!isValidTextHighlight}
                    autoFocus
                    margin="dense"
                    name="text_highlight"
                    label="하이라이트"
                    type="text"
                    fullWidth
                    defaultValue={editEx.text_highlight}
                    onChange={(e) => {
                        onChangeTF(type, e);
                    }}
                />
                <div className="WordDialog_audio">
                    <div style={{ color: "gray" }}>오디오 URL (선택):</div>
                    <div
                        className="WordDialog_ad-editBtn"
                        onClick={() => {
                            document
                                .getElementById(`input-editExAudio`)
                                .click();
                        }}
                    >
                        {editEx.audio_url ? "수정" : "업로드"}
                    </div>
                    <div
                        className="WordDialog_ad-fileName"
                        onClick={() => {
                            onClickPlayAudio({
                                audio_url: editEx.audio_url,
                            });
                        }}
                    >
                        {editEx.audio_url ? editEx.audio_url : ""}
                    </div>
                    <input
                        id={`input-editExAudio`}
                        type="file"
                        name="file"
                        accept=".mp3"
                        onChange={(e) => {
                            onChangeTF(type, e);
                        }}
                        hidden
                    />
                </div>
                <TextField
                    error={!isValidWrongAnswers}
                    autoFocus
                    margin="dense"
                    name="wrong_answers"
                    label="오답선지"
                    type="text"
                    fullWidth
                    defaultValue={editEx.wrong_answers}
                    onChange={(e) => {
                        onChangeTF(type, e);
                    }}
                />
                <DialogContentText>
                    모든 오답은 , 로 구분되며 최소 3개 이상의 오답 선지가 있어야
                    합니다.
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button
                    onClick={() => {
                        handleDialog(type, false);
                    }}
                >
                    취소
                </Button>
                <Button
                    variant="contained"
                    onClick={() => {
                        onClickDelete(type, editEx);
                    }}
                    color={"secondary"}
                >
                    의미 삭제
                </Button>
                <Button
                    variant="contained"
                    onClick={() => {
                        if (!isValid) return;
                        onClickEdit(type);
                    }}
                    color={isValid ? "primary" : "secondary"}
                >
                    수정
                </Button>
            </DialogActions>
        </Dialog>
    );
};

export default ExDialog;
