import { URL } from "./Constant";
import * as loginHelper from "./LoginHelper";

async function request(url, params, method = "GET") {
    const options = {
        method,
        headers: {
            "Content-Type": "application/json",
        },
    };

    const user = loginHelper.getUser();
    if (user) {
        options.headers["Authorization"] = user.token;
    }

    if (params) {
        if (method === "GET" || method === "DELETE") {
            url += "?" + objectToQueryString(params);
        } else {
            if (url.includes("upload")) {
                delete options.headers["Authorization"];
                delete options.headers["Content-Type"];
                options.body = params;
            } else {
                options.body = JSON.stringify(params);
            }
        }
    }

    const response = await fetch(URL.API_SERVER + url, options);
    const result = await response.json();
    if (response.status !== 200) {
        if (result.status || result.detail) {
            window.alert(
                `[${result.error_code}] [status: ${result.status_code}] ${result.detail}`
            );
        } else {
            window.alert(`서버 에러`);
        }
        throw Error("error code");
    }

    return result;
}

function objectToQueryString(obj) {
    return Object.keys(obj)
        .map((key) => key + "=" + obj[key])
        .join("&");
}

export const GET = (url, params) => {
    return request(url, params);
};

export const POST = (url, params) => {
    return request(url, params, "POST");
};

export const PUT = (url, params) => {
    return request(url, params, "PUT");
};

export const DELETE = (url, params) => {
    return request(url, params, "DELETE");
};

// export { GET, POST, PUT, DELETE };
