import React from "react";

 const View = (props) => {
    return (
        <div className="Home">
            <div className="container">
                {`Clabvoca 페이지 입니다. 현재 페이지 개발중 입니다. \nThis is Clabvoca page. The page is currently being developed. \nIf you have any question, please email "info@yourcode.co.kr"`}
            </div>
        </div>
    );
}
export default View
