import {
    TextField,
    DialogContentText,
    Button,
    InputLabel,
    FormControl,
    NativeSelect,
} from "@material-ui/core";

import ExampleSentenceBox from "./ExampleSentenceBox";

const MeaningBox = (props) => {
    const {
        type,
        meaning,
        onChangeMeaningBox,
        meaningBoxIndex,
        onHandleExBoxInAddWord,
        onChangeExBox,
        onClickPlayAudio,
    } = props;
    return (
        <div
            style={{
                marginTop: "10px",
                padding: "10px",
                border: meaning.isValid ? "2px solid gray" : "2px solid red",
                width: "800px",
                marginLeft: "20px",
                borderRadius: "10px",
            }}
        >
            <FormControl className="">
                <InputLabel shrink htmlFor="age-native-label-placeholder">
                    품사
                </InputLabel>
                <NativeSelect
                    value={meaning.FK_part_of_speech}
                    onChange={(e) => {
                        onChangeMeaningBox(type, meaningBoxIndex, e);
                    }}
                    inputProps={{
                        name: "FK_part_of_speech",
                        // id: "FK_part_of_speech",
                    }}
                >
                    <option value={-1}>선택</option>
                    <option value={1}>명사</option>
                    <option value={2}>동사</option>
                    <option value={3}>형용사</option>
                    <option value={4}>부사</option>
                    <option value={5}>대명사</option>
                    <option value={6}>접속사</option>
                    <option value={7}>전치사</option>
                </NativeSelect>
            </FormControl>
            <TextField
                error={false}
                autoFocus
                margin="dense"
                name="text_kor"
                label="의미"
                type="text"
                fullWidth
                defaultValue={meaning.text_kor}
                onChange={(e) => {
                    onChangeMeaningBox(type, meaningBoxIndex, e);
                }}
            />
            <TextField
                error={false}
                autoFocus
                margin="dense"
                name="answer"
                label="정답"
                type="text"
                fullWidth
                defaultValue={meaning.answer}
                onChange={(e) => {
                    onChangeMeaningBox(type, meaningBoxIndex, e);
                }}
            />

            <TextField
                error={false}
                autoFocus
                margin="dense"
                name="wrong_answers"
                label="오답선지"
                type="text"
                fullWidth
                defaultValue={meaning.wrong_answers}
                onChange={(e) => {
                    onChangeMeaningBox(type, meaningBoxIndex, e);
                }}
            />
            <DialogContentText>
                모든 오답은 , 로 구분되며 최소 3개 이상의 오답 선지가 있어야
                합니다.
            </DialogContentText>

            {meaning.ExampleSentences.map((ex, index) => {
                return (
                    <ExampleSentenceBox
                        key={index}
                        type={type}
                        exBoxIndex={index}
                        meaningBoxIndex={meaningBoxIndex}
                        ex={ex}
                        onChangeExBox={onChangeExBox}
                        onClickPlayAudio={onClickPlayAudio}
                    />
                );
            })}

            <Button
                onClick={() => {
                    onHandleExBoxInAddWord(type, true, meaningBoxIndex);
                }}
                color={"primary"}
            >
                예문 추가
            </Button>
            <Button
                onClick={() => {
                    onHandleExBoxInAddWord(type, false, meaningBoxIndex);
                }}
                color={"primary"}
            >
                예문 삭제
            </Button>
        </div>
    );
};

export default MeaningBox;
