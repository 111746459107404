import React, { Component } from "react";
import View from "./View.js";

export class Container extends Component {
    componentDidMount = async () => {};
    componentDidUpdate = async (_, prevState) => {};

    render() {
        return <View />;
    }
}

export default Container;
