export function setUser(user) {
    const { token, level } = user;
    sessionStorage.setItem("token", token);
    sessionStorage.setItem("level", level);
}

export function getUser() {
    const token = sessionStorage.getItem("token");
    const level = sessionStorage.getItem("level");
    return token ? { token, level } : undefined;
}

export function isLogin() {
    const token = sessionStorage.getItem("token");
    return token ? true : false;
}

export function logout() {
    sessionStorage.removeItem("token");
    sessionStorage.removeItem("level");
}
