import "./App.scss";
import { BrowserRouter, Route } from "react-router-dom";

import Login from "./Page/Login";
import Terms from "./Page/Terms";
import Home from "./Page/Home";
import User from "./Page/User";
import AddAdmin from "./Page/AddAdmin";
import SendEmail from "./Page/SendEmail";

import Word from "./Page/Word";

import SerialNum from "./Page/SerialNum";

import InstaShare from "./Page/InstaShare";
import Inquiry from "./Page/Inquiry";

function App() {
    return (
        <BrowserRouter>
            <div className="App">
                <Route
                    exact
                    path="/phpmyadmin"
                    render={(_) => {
                        const aTag = document.createElement("a");
                        aTag.href = "http://db.clabvoca.net/phpmyadmin";
                        aTag.click();
                    }}
                ></Route>
                <Route exact path="/" component={Login} />
                <Route exact path="/terms/:type" component={Terms} />
                <Route exact path="/home" component={Home} />
                <Route exact path="/users" component={User} />
                <Route exact path="/addAdmin" component={AddAdmin} />
                <Route exact path="/SendEmail" component={SendEmail} />

                <Route exact path="/words" component={Word} />

                <Route exact path="/serialnum" component={SerialNum} />
                <Route exact path="/instashare" component={InstaShare} />
                <Route exact path="/inquiry" component={Inquiry} />
            </div>
        </BrowserRouter>
    );
}

export default App;
