import { TextField, DialogContentText } from "@material-ui/core";

const ExampleSentenceBox = (props) => {
    const {
        type,
        ex,
        exBoxIndex,
        meaningBoxIndex,
        onChangeExBox,
        onClickPlayAudio,
    } = props;

    return (
        <div
            style={{
                marginTop: "10px",
                padding: "10px",
                border: ex.isValid ? "2px solid gray" : "2px solid red",
                width: "600px",
                marginLeft: "20px",
                borderRadius: "10px",
            }}
        >
            <TextField
                error={false}
                autoFocus
                margin="dense"
                name="text_eng"
                label="예문"
                type="text"
                fullWidth
                defaultValue={ex.text_eng}
                onChange={(e) => {
                    onChangeExBox(type, meaningBoxIndex, exBoxIndex, e);
                }}
            />
            <TextField
                error={false}
                autoFocus
                margin="dense"
                name="text_kor"
                label="의미"
                type="text"
                fullWidth
                defaultValue={ex.text_kor}
                onChange={(e) => {
                    onChangeExBox(type, meaningBoxIndex, exBoxIndex, e);
                }}
            />
            <TextField
                error={false}
                autoFocus
                margin="dense"
                name="text_highlight"
                label="하이라이트"
                type="text"
                fullWidth
                defaultValue={ex.text_highlight}
                onChange={(e) => {
                    onChangeExBox(type, meaningBoxIndex, exBoxIndex, e);
                }}
            />

            <div className="WordDialog_audio">
                <div style={{ color: "gray" }}>오디오 URL (선택):</div>
                <div
                    className="WordDialog_ad-editBtn"
                    onClick={() => {
                        document
                            .getElementById(
                                `input-exAudio-${meaningBoxIndex}-${exBoxIndex}`
                            )
                            .click();
                    }}
                >
                    {ex.audio_url ? "수정" : "업로드"}
                </div>
                <div
                    className="WordDialog_ad-fileName"
                    onClick={() => {
                        onClickPlayAudio({
                            audio_url: ex.audio_url,
                        });
                    }}
                >
                    {ex.audio_url ? ex.audio_url : ""}
                </div>
                <input
                    id={`input-exAudio-${meaningBoxIndex}-${exBoxIndex}`}
                    type="file"
                    name="file"
                    accept=".mp3"
                    onChange={(e) => {
                        onChangeExBox(type, meaningBoxIndex, exBoxIndex, e);
                    }}
                    hidden
                />
            </div>

            <TextField
                error={false}
                autoFocus
                margin="dense"
                name="wrong_answers"
                label="오답선지"
                type="text"
                fullWidth
                defaultValue={ex.wrong_answers}
                onChange={(e) => {
                    onChangeExBox(type, meaningBoxIndex, exBoxIndex, e);
                }}
            />
            <DialogContentText>
                모든 오답은 , 로 구분되며 최소 3개 이상의 오답 선지가 있어야
                합니다.
            </DialogContentText>
        </div>
    );
};

export default ExampleSentenceBox;
