import React from "react";
import View from "./View.js";
import * as Fetch from "../../Common/Util/Fetch.js";
import ClabComponent from "../../Common/Util/ClabComponent";

export class Container extends ClabComponent {
    constructor(props) {
        super(props);
        this.table = React.createRef();
        this.state = {
            data: [],
            pages: null,
            loading: true,
            itemCount: 0,
            pageSize: 20,

            answerLoading: false,
            inquiry: undefined,
        };
    }

    componentDidMount() {}

    getData = async (state, instance) => {
        try {
            await this.updateState({ loading: true });

            let { page, pageSize, sorted, filtered } = state;
            const params = { page, pageSize, sorted, filtered };
            const inquiryInfo = await Fetch.GET("/admin/inquiries", params);

            await this.updateState({
                data: inquiryInfo.inquiries.rows,
                pages: Math.ceil(inquiryInfo.inquiries.count / pageSize),
                loading: false,
                itemCount: inquiryInfo.inquiries.count,
            });
        } catch (error) {
            console.error(error);
        }
    };

    refreshTable = () => {
        this.getData(this.table.current.state);
    };

    onClickAnswer = async (inquiry) => {
        try {
            await this.updateState({
                answerLoading: true,
            });
            const params = {
                answer: inquiry.answer,
                inquiryId: inquiry.id,
            };
            await Fetch.PUT("/admin/inquiry", params);
            this.handleDialog(undefined);
            this.refreshTable();
        } catch (error) {}
        await this.updateState({
            answerLoading: false,
        });
    };

    handleDialog = async (inquiry) => {
        await this.updateState({
            inquiry:
                inquiry === undefined
                    ? inquiry
                    : JSON.parse(JSON.stringify(inquiry)),
        });
    };

    onChangeAnswer = (e) => {
        const { inquiry } = this.state;
        const value = e.target.value;
        inquiry.answer = value;
        this.setState({
            inquiry,
        });
    };

    render() {
        const { itemCount, data, loading, pages, pageSize } = this.state;
        const { inquiry, answerLoading } = this.state;

        const { getData, onClickAnswer, handleDialog, onChangeAnswer } = this;
        return (
            <View
                table={this.table}
                itemCount={itemCount}
                data={data}
                loading={loading}
                pages={pages}
                pageSize={pageSize}
                getData={getData}
                onClickAnswer={onClickAnswer}
                handleDialog={handleDialog}
                inquiry={inquiry}
                answerLoading={answerLoading}
                onChangeAnswer={onChangeAnswer}
            />
        );
    }
}

export default Container;
