import React from "react";
import View from "./View.js";
import * as Fetch from "../../Common/Util/Fetch.js";
import ClabComponent from "../../Common/Util/ClabComponent";

export class Container extends ClabComponent {
    constructor(props) {
        super(props);
        this.table = React.createRef();
        this.state = {
            data: [],
            pages: null,
            loading: true,
            itemCount: 0,
            pageSize: 20,
        };
    }

    componentDidMount() {}

    getData = async (state, instance) => {
        try {
            await this.updateState({ loading: true });

            let { page, pageSize, sorted, filtered } = state;
            const params = { page, pageSize, sorted, filtered };
            const instaSharesInfo = await Fetch.GET(
                "/admin/instashares",
                params
            );

            await this.updateState({
                data: instaSharesInfo.instaShares.rows,
                pages: Math.ceil(instaSharesInfo.instaShares.count / pageSize),
                loading: false,
                itemCount: instaSharesInfo.instaShares.count,
            });
        } catch (error) {
            console.error(error);
        }
    };

    refreshTable = () => {
        this.getData(this.table.current.state);
    };

    render() {
        const { itemCount, data, loading, pages, pageSize } = this.state;

        const { getData } = this;
        return (
            <View
                table={this.table}
                itemCount={itemCount}
                data={data}
                loading={loading}
                pages={pages}
                pageSize={pageSize}
                getData={getData}
            />
        );
    }
}

export default Container;
